import React from 'react';
import {useGlobal} from "reactn";
import "./NavBar.sass";
import {FiCalendar, FiHome, FiMessageCircle, FiSettings, FiStar, FiDollarSign, FiUsers, FiSearch, FiSmile} from 'react-icons/fi';

const NavBar = () => {
    const [nav, setNav] = useGlobal('nav');

    return (
        <div className="bottombar uk-flex">
            <div className={`item${nav === 'profile' ? ' active' : ''}`} onClick={() => setNav('profile')}>
            <div className="icon">
                <FiHome/>
            </div>
            <div className="text">Home</div>
            </div>

            <div className={`item${nav === 'rooms' ? ' active' : ''}`} onClick={() => setNav('rooms')}>
                <div className="icon">
                    <FiMessageCircle/>
                </div>
                <div className="text">Messages</div>
            </div>
            <div className={`item${nav === 'settings' ? ' active' : ''}`} onClick={() => setNav('settings')}>
                <div className="icon">
                    <FiSettings/>
                </div>
                <div className="text">Account</div>
            </div>
            <div className={`item${nav === 'meetings' ? ' active' : ''}`} onClick={() => {
                setNav('meetings');
            }}>
                <div className="icon">
                    <FiDollarSign/>
                </div>
                <div className="text">Earnings</div>
            </div>
        </div>
    );
/*
            <div className={`item${nav === 'favorites' ? ' active' : ''}`} onClick={() => setNav('favorites')}>
                <div className="icon">
                    <FiStar/>
                </div>
                <div className="text">Favorites</div>
            </div>
*/

}

export default NavBar;
