import React, {useRef, useState} from 'react';
import {useGlobal} from "reactn";
import Picture from "../../components/Picture";
import TopBar from "./components/TopBar";
import {Route, useHistory} from "react-router-dom";
import BottomBar from "./components/BottomBar";
import { setCookie, getCookie } from "../../utils.js"
import getMeetings from "../../actions/getMeetings";
import * as moment from "moment";
import Rating from '@material-ui/lab/Rating';

const Welcome = () => {

//    const user = useGlobal('user')[0];
    const [getOver, setOver] = useGlobal('over');

    const back = () => {
        setOver(false);
        history.replace('/');
    }
    const textAreaRef = useRef(null);    
    
    const copyToClipboard = (e) => {

        textAreaRef.current.select();
        document.execCommand('copy');
        e.target.focus();
    };

    const setToken = useGlobal('token')[1];
    const [user, setUser] = useGlobal('user');    
    const history = useHistory();

    const [meetings, setMeetings] = useGlobal('meetings');
    getMeetings().then(res => setMeetings(res.data.meetings)).catch(err => console.log(err));

    console.log('Loading welcome... meetings=',meetings);
    
    if(!meetings || !meetings[0]) {
        history.replace('/');
        return null;
    }
    
    var totalPaid = 0;
    var totalEarned = 0;
    var totalSeconds = 0;
    var totalMinutes = 1;
    var lastMeeting = meetings[0];
    var isExpert = (user.price1);
    var minutesText = "";
    var todaysDate = moment().format("dddd, MMMM Do YYYY");
    
    totalPaid = lastMeeting.totalCharged / 100;
    totalEarned = Math.floor(lastMeeting.totalCharged * 0.8) / 100;
    var ms = moment(lastMeeting.lastLeave,"YYYY-MM-DDTHH:mm:ss").diff(moment(lastMeeting.lastEnter,"YYYY-MM-DDTHH:mm:ss"));        
    totalSeconds = moment.duration(ms).seconds();
    totalMinutes = Math.ceil(totalSeconds / 60);
        
    minutesText = moment().format('dddd, MMMM Do, YYYY -- hh:mm:ss A');
    
    if(totalMinutes == 1)
        minutesText = '1 minute';
    else
        minutesText = totalMinutes + " minutes";
    
    if(!isExpert) {
        localStorage.removeItem('token');
        setCookie('token','',0);
        setCookie('pw','',0);
        setCookie('email','',0);
        setCookie('user','',0);
    //    setToken(null);
    //    setUser({});
    }

/*
    var now = moment(new Date());
    var then = moment(lastMeeting.lastLeave);
    var timeSinceLast = moment.duration(now.diff(then));
    
    console.log('time since last = ',moment.duration(timeSinceLast).asSeconds());
    
    if(!getOver && totalMinutes > 0 && moment.duration(timeSinceLast).asSeconds() < 7)
        setOver(true);
*/
/*
    if(totalMinutes == 0) {
        back();
    }
*/  
if(isExpert)
    return (
    <div>
      <div class="postcall-wrapper-thankyou">
        
        <div class="postcall-thankyou">Thank you for using</div>
        <div class="postcall-logowrapper">
            <img class="postcall-logo" src="AVYZR-logo-horizontal-large.png"/>
        </div>
      </div>
        
        <div class="postcall-topsection-header">{todaysDate}</div>                         
        <p class="postcall-timelabel">Total call time</p>
        <p class="postcall-time">{minutesText}</p>   

        <p class="postcall-earningslabel">You earned</p> <p class="postcall-earnings">${totalEarned}</p>
    
        <div class="postcall-ratingwrapper">
            <p class="postcall-ratinglabel">How was your experience with Avyzr on this call?</p>
            <div class="postcall-ratingbox">
                <Rating />
            </div>
        </div>
    
        <div className="startButtonWrapper">
            <button class="startbutton" onClick={back}>Continue</button>
        </div>

    </div>
    );
else
    return (
    <div>
        <div class="postcall-wrapper-thankyou">
            <div class="postcall-thankyou">Thank you for using</div>
            <div class="postcall-logowrapper">
                <img class="postcall-logo" src="AVYZR-logo-horizontal-large.png"/>
            </div>
        </div>
        
        <div class="postcall-topsection-header">{todaysDate}</div>                         
        <p class="postcall-timelabel">Total call time</p>
        <p class="postcall-time">{minutesText}</p>   

        <p class="postcall-earningslabel">Total paid</p> <p class="postcall-earnings">${totalPaid}</p>
    
        <div class="postcall-ratingwrapper">
            <p class="postcall-ratinglabel">How was your experience with Avyzr on this call?</p>
            <div class="postcall-ratingbox">
                <Rating />
            </div>
        </div>
    
        <div className="startButtonWrapper">
            <button class="startbutton" onClick={back}>Continue</button>
        </div>

    </div>
    );
    
    
   const logout = async () => {
        localStorage.removeItem('token');
        setCookie('token','',0);
        setCookie('pw','',0);
        setCookie('email','',0);
        setCookie('user','',0);
        await setToken(null);
        await setUser({});
        history.replace("/login");
    };    
    
    console.log('Loading Welcome with user',user);

if(user && 0) {
    
/* LOGOUT IF NOT AN EXPERT USER */

    if(!user.price1)
        logout();
    else
        history.replace("/login");

/* LOGOUT DONE */
    
    return(null);
}
    
    function uniqueLink(user) {
      return 'http://avyzr.com/?id='+user.username;
    }    
    
    return (
        <div className="content uk-flex uk-flex-column">
            <TopBar back={back} />
            <div className="content uk-flex uk-flex-center uk-flex-middle uk-flex-column">
                <div className="welcome uk-flex uk-flex-bottom">
                    {user.firstName} {user.lastName}
                </div>
                <div className="profile">
                    <Picture user={user}/>
                </div>
                <div className="tutorial uk-flex uk-flex-top uk-flex-column uk-flex-center uk-flex-middle" style={{ paddingTop:30, "width" : "100%" }}>
        
                    <br/><br/><br/><br/>
                    
                    Your unique link is <form style={{ "width" : "95%"}}><textarea ref={textAreaRef} value={uniqueLink(user)} style={{ background:'transparent',borderWidth:0, fontWeight:600, "width" : "100%", textAlign: "center",
    flex: 1 }}></textarea></form><br/><br/>
                    Share it to others now<br/>
                                <button class="uk-button uk-button-primary uk-button-large" onClick={copyToClipboard}>Copy Link</button>
                </div>
            </div>
            <BottomBar/>
        </div>
    ); 

    
/*
   return (
        <div className="content uk-flex uk-flex-column">
            <TopBar back={back} />
            <div className="content uk-flex uk-flex-center uk-flex-middle uk-flex-column">
                <div className="welcome uk-flex uk-flex-bottom">
                    {user.firstName} {user.lastName}
                </div>
                <div className="profile">
                    <Picture user={user}/>
                </div>
                <div className="tutorial uk-flex uk-flex-top uk-flex-column uk-flex-center uk-flex-middle">
                    Search for someone to start a conversation,<br/>
                    Add contacts to your favorites to reach them faster
                </div>
            </div>
            <BottomBar/>
        </div>
    ); 
*/
}


export default Welcome;
