import React, {useEffect, useState, useRef} from 'react';
import {useGlobal} from "reactn";
import "./ProfilePage.sass";
import createRoom from "../../actions/createRoom";
import {useDispatch, useSelector} from "react-redux";
import Actions from "../../constants/Actions";
import {useLocation} from "react-router-dom";
import {useHistory} from "react-router-dom";
import register from "../../actions/register";
import Config from "../../config";

import Picture from "../../components/Picture";
import {BrowserRouter as Router, Switch, Route, useParams, Redirect} from "react-router-dom";

import TopBar from "./components/TopBar";
import NavBar from "./components/NavBar";

import {FiZap, FiTablet, FiAward} from 'react-icons/fi';
import Input from "./components/Input";
import { setCookie, getCookie } from "../../utils.js"
import jwtDecode from "jwt-decode";
import setAuthToken from "../../actions/setAuthToken";
import login from "../../actions/login";

import initIO from "../../actions/initIO";

import {useToasts} from "react-toast-notifications";
import getMeetingRoom from "../../actions/getMeetingRoom";
import postCall from "../../actions/postCall";

import './w3.css';

import ReactGA from 'react-ga';

import StripeContainer from "../Stripe/StripeContainer";

import SocialLogin from '../SocialLogin/SocialLogin';
import SocialLogout from '../SocialLogin/SocialLogout';

import message from "../../actions/message";
import getRooms from "../../actions/getRooms";
import moment from "moment";

import Avatar from 'react-avatar';


var origEntryID = '';
var profilename='';
var profiledesc='';
var profileFirstInit='';
var profileLastInit='';
var profilePicShieldedID='';
var profileID='';
var profilePrice='2.99';

const title = 'Chat';

const ProfilePage = () => {

    const [entryPath, setEntryPath] = useGlobal('entryPath');
    
    const [firstEntryPath, setFirstEntryPath] = useGlobal('firstEntryPath');
    console.log('loading ProfilePage... firstEntryPath='+firstEntryPath);

    const [loginVisible, setLoginVisible] = useState(false);
    const [loginDone, setLoginDone] = useState(false);
    const [paymentVisible, setPaymentVisible] = useState(false);
    
    const [precallPage, setPrecallPage] = useGlobal('precallPage');
    
    let params = useParams();
    
    console.log(params);
    console.log(params.id);
            
    const hist = useHistory();
    const location = useLocation();
    const disp = useDispatch();
    const dispatch = useDispatch();

    const [registerUsername, setRegisterUsername] = useState('');
    const [registerEmail, setRegisterEmail] = useState('');
    const [registerFirstName, setRegisterFirstName] = useState('');
    const [registerLastName, setRegisterLastName] = useState('');
    const [registerPassword, setRegisterPassword] = useState('');
    const [registerRepeatPassword, setRegisterRepeatPassword] = useState('');
    const [registerErrors, setRegisterErrors] = useState({});
    
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [keep, setKeep] = useState(true);

    const [loginErrors, setLoginErrors] = useState({});

    const [cardNumber, setCardNumber] = useState('');
    const [expDate, setExpDate] = useState('');
    
    let lastEntryID = params.id;
    
    const [targetProfile, setTargetProfile] = React.useState([]);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);    

    const [token, setToken] = useGlobal('token');
    const [user,setUser] = useGlobal('user');

    const setAudio = useGlobal('audio')[1];
    const setVideo = useGlobal('video')[1];
    const setCallDirection = useGlobal('callDirection')[1];
    const setMeeting = useGlobal('meeting')[1];
    
    const [over, setOver] = useGlobal('over');
    const showPanel = useGlobal('showPanel')[0];

    const warningToast = content => {
        addToast(content, {
            appearance: 'warning',
            autoDismiss: true,
        })
    };

    const errorToast = content => {
        addToast(content, {
            appearance: 'error',
            autoDismiss: true,
        })
    };

    const { addToast } = useToasts();

    let other = {};

    const [nav, setNav] = useGlobal('nav');

    const chat = async (calleeID, isVideo, room) => {
        console.log('chat('+calleeID+','+isVideo+', room=');
        console.log(room);

        setNav('rooms');
        const target = `/room/${room._id}`;

        console.log('setting preChatPage to ' + target);    
        console.log('setting preChatShowPanelto '+showPanel);    
        console.log('setting preChatOver to '+over);            

        window.preChatPage = target;
        window.preChatShowPanel = false;
        window.preChatOver = true;

        dispatch({type: Actions.SET_ROOM, room: room});
        dispatch({type: Actions.SET_MESSAGES, messages: room.messages});
        if (location.pathname !== target) hist.replace(target);
    };

   const call = async (calleeID, isVideo, room) => {
     console.log('call('+calleeID+','+isVideo+', room=');
       console.log(room);
       
        room.people.forEach(person => {
            if (user.id !== person._id) other = person;
        });

        if (!other.firstName) {
            other = { ...other, firstName: 'Deleted', lastName: 'User' };
        }

       console.log('other=');
       console.log(other);
       
        await setAudio(true);
        await setVideo(isVideo);
        await setCallDirection('outgoing');
        window.callDirection = 'outgoing';
        dispatch({type: Actions.RTC_SET_COUNTERPART, counterpart: other});
        try {
            const res = await getMeetingRoom({startedAsCall: true, caller: user.id, callee: other._id, callToGroup: room.isGroup, group: room._id});
            console.log('getMeetingRoom returned:');
            console.log(res.data);
            await setMeeting(res.data);
            await setPrecallPage(window.location.pathname);
             console.log('ProfilePage.js set precallPage = ' + precallPage);
            
            
            hist.replace('/meeting/' + res.data._id);
            await postCall({roomID: room._id, meetingID: res.data._id});

        }
        catch (e) {
            errorToast('Server error. Unable to initiate call.');
        }
    };    
    

    const chatgo = async (paymentDone, currentUser, currentToken) => {
        console.log('chatgo(',paymentDone,currentUser,currentToken,')');
        console.log('chatgo with profileID='+profileID+' and    token='+token);
        console.log(user);
        
        if(profileID == '')
            return;

        if(!currentToken || !currentUser) {
            console.log('ProfilePage chatgo !token or !user ');
            await setLoginVisible(true);
            return;
        }
        else if(!paymentDone) {
            console.log("ProfilePage chatgo !paymentDone");
            await setPaymentVisible(true);  
            return;
        }
            
        const res = await createRoom(profileID);
        const room = res.data.room;

        // Create welcome message
            console.log('Creating welcome message... existing messages:', room.messages);
            room.people.forEach(person => {
                if (user.id !== person._id) other = person;
            });

            const pricePerMinute = other ? other.price1/100 : "Error";
            const minimumMinutes = other ? other.minutes1 : "Error";
            const text = "Welcome to my room! I am available for calls at a rate of $"+pricePerMinute+" per minute with a minimum of "+minimumMinutes+" minutes.  How can I help you today?  Please note, you won't be charged until we start our actual call.";
        
            message({roomID: res.data.room._id, authorID: other._id, content: text, contentType: 'text'}).then(() => {
                getRooms().then(resp => dispatch({type: Actions.SET_ROOMS, rooms: resp.data.rooms})).catch(err => console.log(err));
            });
            let newMessage = {
                _id: Math.random(), author: {...other, _id: other.id}, content: text, type: 'text', date: moment()
            };
            dispatch({type: Actions.MESSAGE, message: newMessage});



        await chat(profileID, true, res.data.room);
        return;

        const target = `/room/${res.data.room._id}`;
        disp({type: Actions.SET_ROOM, room: res.data.room});
        disp({type: Actions.SET_MESSAGES, messages: res.data.room.messages});
        if (location.pathname !== target) hist.replace(target);
    };

    
    const getProfileData = async (entryID) => { 
        console.log('getProfileData('+entryID+')');
        if(entryID) {

             try {
                 setLoading(true);
                 setTargetProfile(null);
                 setError(null);

                ReactGA.event({
                  category: 'User',
                  action: 'ProfileView',
                  label: entryID
                });


                 await register({
                    username: entryID,
                    loginType: 'search',
                    firstName: 'test',
                    lastName: 'test',
                    password: 'test',
                    repeatPassword: 'test',
                }).then(matchingUser => { 
                     console.log('matchingUser=');
                     console.log(matchingUser);
                    console.log(matchingUser.data); 
                     console.log('pic=');
                     console.log(matchingUser.data.picture);
                     
                     if(matchingUser.data.picture)
                        console.log(matchingUser.data.picture.shieldedID);

                    if(!matchingUser)
                        return;
                    console.log('username='+matchingUser.data.username);         
                    profilename = matchingUser.data.firstName + ' ' + matchingUser.data.lastName;
                    profiledesc = matchingUser.data.tagLine;
                    profileID = matchingUser.data._id;
                    setFirstEntryPath(window.location.pathname);

                    if(matchingUser.data.firstName)
                        profileFirstInit = matchingUser.data.firstName.substr(0,1);
                    if(matchingUser.lastName)
                        profileLastInit = matchingUser.data.lastName.substr(0,1);
                    if(matchingUser.data.picture)
                        profilePicShieldedID = matchingUser.data.picture.shieldedID;
                     console.log('profilePicShieldedID set to '+profilePicShieldedID);

                     if(matchingUser.data.price1)
                         profilePrice = parseFloat(matchingUser.data.price1/100).toString();
                     
                     setTargetProfile(matchingUser.data);
                 });

            }
            catch (e) {
                let errors = {};
                if (!e.response || typeof e.response.data !== 'object') errors.generic = 'Could not connect to server.';
                else errors = e.response.data;
            }
            finally {
                 setLoading(false);
            }
        }
    }
    
    React.useEffect(() => {
         console.log('Login useEffect with lastentryID='+lastEntryID);
         if(lastEntryID)   
            getProfileData(lastEntryID);
        }, []); 

    
    
    if(lastEntryID  && profilename=='') {
        console.log('No matching user');
                return ( 
                    <div className="app" class="app app-gray"> </div>        
                );
    }

    const Picture = () => {
        if (profilePicShieldedID != '')
            return <img class="profilepic" src={`${Config.url || ''}/api/images/${profilePicShieldedID}/256`} alt="Picture" className="picture profilepic"/>;
        else
            return <div className="profilepage-defaultpic"><Avatar name={profilename} round={true} color="#5500FF" size="150" /></div>;
    };
                 
    console.log('name='+profilename);
    console.log('desc='+profiledesc);
    console.log('picid='+profilePicShieldedID);
    
    const loginInfo = Object.keys(loginErrors).map(key => (
        <div className="uk-text-center" key={key}>
            <span className="uk-text-danger">{loginErrors[key]}</span>
        </div>
    ));
    
   const registerInfo = Object.keys(registerErrors).map(key => (
    <div className="uk-text-center" key={key}>
        <span className="uk-text-danger">{registerErrors[key]}</span>
    </div>
    ));

    const onLogin = async e => {
        e.preventDefault();
        try {
            console.log('Storing email and PW in cookie');
            
            setCookie('pw',password,365);
            setCookie('email',email,365);

            const res = await login(email, password);
            
            await setCookie('token',res.data.token,365);
            await setCookie('user',JSON.stringify(jwtDecode(res.data.token)),365);
            
            setLoginErrors({});
            await setAuthToken(res.data.token);
            await setUser(jwtDecode(res.data.token));
            await setToken(res.data.token);
            await dispatch(initIO(res.data.token));
            console.log('login.js onLogin firstEntryPath='+firstEntryPath);

            await setLoginVisible(false);
            await setLoginDone(true);
            await setPaymentVisible(true);
            await chatgo();
            
        }
        catch (e) {
            let errors = {};
            if (!e.response || typeof e.response.data !== 'object') errors.generic = 'Could not connect to server.';
            else errors = e.response.data;
            setLoginErrors(errors);
        }
    };

    const onPaySubmit = async e => {
        e.preventDefault();
        console.log('onPaySubmit()');
        await chatgo();
        return;
    }
    
    const onEmailSubmit = async e => {
        e.preventDefault();
        console.log('onEmailSubmit()');
        await chatgo();
        return;
    }

    const onRegister = async e => {
        e.preventDefault();
        try {
            await register({
                username: registerUsername,
                email: registerEmail,
                firstName: registerFirstName,
                lastName: registerLastName,
                password: registerPassword,
                repeatPassword: registerRepeatPassword,
            });
            
            console.log('Storing email and PW in cookie');
            setCookie('pw',password,365);
            setCookie('email',email,365);
                      
            const res = await login(registerEmail, registerPassword);
            setRegisterErrors({});            
            
            setCookie('token',res.data.token,365);
            setCookie('user',jwtDecode(res.data.token),365);
            
            setAuthToken(res.data.token);
            setUser(jwtDecode(res.data.token));
            setToken(res.data.token);
            dispatch(initIO(res.data.token));
        }
        catch (e) {
            let errors = {};
            if (!e.response || typeof e.response.data !== 'object') errors.generic = 'Could not connect to server.';
            else errors = e.response.data;
            setRegisterErrors(errors);
        }
    };

    const paymentRequest = {
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: [
          {
            type: 'CARD',
            parameters: {
              allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
              allowedCardNetworks: ['MASTERCARD', 'VISA'],
            },
            tokenizationSpecification: {
              type: 'PAYMENT_GATEWAY',
              parameters: {
                gateway: 'example',
                gatewayMerchantId: 'exampleGatewayMerchantId',
              },
            },
          },
        ],
        merchantInfo: {
          merchantId: '12345678901234567890',
          merchantName: 'Avyzr',
        },
        transactionInfo: {
          totalPriceStatus: 'FINAL',
          totalPriceLabel: 'Total',
          totalPrice: '100.00',
          currencyCode: 'USD',
          countryCode: 'US',
        },
        emailRequired: true
      };

const handleLoadPaymentData = async (paymentData) => {
  	console.log('load payment data', paymentData);
            await setLoginVisible(false);
            await setLoginDone(true);
            await setPaymentVisible(true);
            await chatgo();
  }    

const handleStripePaymentMethodSuccess = async (paymentData) => {
    console.log('handleStripePaymentMethodSuccess', paymentData);
    console.log('window.currentUser and token',window.currentUser,window.currentToken);
    await chatgo(true, window.currentUser, window.currentToken);
}

const handleSocialLoginSuccess = async (fullRes) => {
    console.log('handleSocialLoginSuccess with ', fullRes);
    
    const socialprofile = fullRes.profileObj;
    
    console.log('searching for username = ',socialprofile.googleId);

    var matchingUser = {};
    var createdUser = {};

        try {
              matchingUser = await register({
                username: socialprofile.googleId,
                email: socialprofile.email,
                loginType: 'search',
                firstName: 'test',
                lastName: 'test',
                password: 'test',
                repeatPassword: 'test'
            });

             console.log('matchingUser=');
             console.log(matchingUser.data); 
                 
            console.log('matchingUser Email = '+matchingUser.data.email);

        }
        catch {

                console.log('No matching user so creating a new one for ' + socialprofile.email);
                createdUser = await register({
                    username: socialprofile.googleId,
                    loginType: 'Google',
                    email: socialprofile.email,
                    firstName: socialprofile.givenName,
                    lastName: socialprofile.familyName,
                    password: fullRes.tokenId,
                    repeatPassword: fullRes.tokenId
                });

                     console.log('CREATED NEW SOICAL USER =');
                     console.log(createdUser);
                     console.log(createdUser.data);

            }
            finally {
                    console.log('Logging in as socialprofile ' + socialprofile.email);
                    const res = await login(socialprofile.email, fullRes.tokenId);

                    const finalUser = await jwtDecode(res.data.token);
                    const finalToken = res.data.token;

                    console.log('login result:', JSON.stringify(finalUser));

                    await setCookie('token',res.data.token,365);
                    await setCookie('user',JSON.stringify(finalUser),365);
            
                    await setLoginErrors({});
                    await setAuthToken(res.data.token);
                    await setUser(finalUser);
                    await setToken(res.data.token);
                    await dispatch(initIO(res.data.token));
                    console.log('ProfilePage.js handleSocial finally');

                     setLoginVisible(false);
                     setLoginDone(true);

                    console.log('ProfilePage HandleSociaLogin finally logged in as ',finalUser);
                    console.log('res = ');
                    console.log(res);
                    console.log('finalUser = ');
                    console.log(finalUser);
                    console.log('matchingUser = ');
                    console.log(matchingUser);
                    console.log('createdUser = ');
                    console.log(createdUser);

                    window.currentUser = finalUser;
                    window.currentToken = finalToken;

                    if (!finalUser.stripePaymentMethod) {
                        console.log("Payment Method does not exist for user - showing payment setup screen");

                        await setPaymentVisible(true);
                        await chatgo(false, finalUser, finalToken);
                    }
                    else {
                        console.log("Payment Method already exists for user - skipping setup screen");
                        await chatgo(true, finalUser, finalToken);
                    }

                    return;

                }

  }    
    
        if(lastEntryID && loading) { console.log('lastEntryID && loading)'); return '';  }
        else if(lastEntryID ) {
                console.log('lastEntryID and not loading');
                return ( 
  <div className="profilescreen">
        <div className="profilepanel">
                        <TopBar/>

    <div class="LoginScreen" style={{ display: (loginVisible && !paymentVisible) ? "block" : "none"}}>
        <p>Please login to continue.</p>
        <form className="toggle-class" onSubmit={onLogin}>
            <fieldset className="uk-fieldset">
                {loginInfo}
                <Input icon="user" placeholder="Email" type="email"
                       onChange={e => setEmail(e.target.value)}/>
                <Input icon="lock" placeholder="Password" type="password"
                       onChange={e => setPassword(e.target.value)}/>
                <div className="uk-margin-large">
                    <label hidden><input className="uk-checkbox" type="checkbox"
                                  onChange={e => setKeep(e.target.checked)} checked={keep} /> Keep me logged in</label>
                </div>
                <div className="uk-margin-bottom">
                    <button type="submit"
                            className="profilebutton2">{ loginDone ? "CONTINUE" : "LOG IN"}
                    </button>
                </div>
            </fieldset>
        </form>  
                     
        <form className="toggle-class" onSubmit={onRegister} hidden>
        {registerInfo}
        <Input icon="user" placeholder="Username" type="text"
               onChange={e => setRegisterUsername(e.target.value)}/>
        <Input icon="mail" placeholder="Email" type="email"
               onChange={e => setRegisterEmail(e.target.value)}/>
        <Input icon="pencil" placeholder="First Name" type="text"
               onChange={e => setRegisterFirstName(e.target.value)}/>
        <Input icon="pencil" placeholder="Last Name" type="text"
               onChange={e => setRegisterLastName(e.target.value)}/>
        <Input icon="lock" placeholder="Password" type="password"
               onChange={e => setRegisterPassword(e.target.value)}/>
        <Input icon="lock" placeholder="Repeat Password" type="password"
               onChange={e => setRegisterRepeatPassword(e.target.value)}/>
        <div className="uk-margin-bottom">
            <button type="submit"
                    className="profilebutton2">REGISTER
            </button>
        </div>
    </form> 
    <div className="uk-text-center">
        <a className="uk-link-reset uk-text-small toggle-class"
           data-uk-toggle="target: .toggle-class ;animation: uk-animation-fade">Need an account?
            Register now!</a>
        <a className="uk-link-reset uk-text-small toggle-class"
           data-uk-toggle="target: .toggle-class ;animation: uk-animation-fade" hidden><span
            data-uk-icon="arrow-left"/> Back to Login</a>
    </div>
</div>
    <div class="paymentSetupScreen" style={{ display: paymentVisible ? "block" : "none" }}>
        <p class="paymentSetupTitle">Account Setup</p>
        <p class="paymentSetupSubtitle">Please link a payment method to your account to continue.  We use Stripe for security, and we only charge your card if/when you initiate a call.</p>

        <StripeContainer action='setup' onSuccessHandler={handleStripePaymentMethodSuccess} />
    </div>            
           
<div class="profileview" style={{ display: (!loginVisible && !paymentVisible) ? "block" : "none"}}>
    <div class="profilecard" >
      <div class="profilecard-content">
          <h2 class="profiletitle">{targetProfile.fullname}{profilename}</h2>
          <p class="profilesubtitle">{profiledesc}</p>
          <Picture/>

          <div class="availableWrapper">
          <p class="availableText" style={{ textAlign: 'center' }}><img class="availableGreenLight" src="https://upload.wikimedia.org/wikipedia/commons/b/b0/Icon_Transparent_Green.png"/> Available</p>

          </div>

        <div class="toggle-1">
        {loginInfo}
        <p class="preLoginText">Login with Google below to see pricing.</p>
        <SocialLogin onSuccess={handleSocialLoginSuccess}/>
        </div>

        <form hidden className="landingpageemailform" onSubmit={onEmailSubmit}>
            {loginInfo}
          <p class="toggle-1 enteremailtext">Enter your e-mail address to see pricing.</p> 

            <fieldset className="uk-fieldset">
                <Input icon="user" placeholder="Enter Your E-Mail Address" type="email"
                       onChange={e => setEmail(e.target.value)}/>
   
                <div className="uk-margin-bottom">
                    <button type="submit"
                            className="profilebutton2" data-uk-toggle="target: .toggle1 ;animation: uk-animation-fade">{ "View Options" }
                    </button>
                </div>

                <div style={{ display: "none" }}>
                    <Input className="landingpagepw" placeholder="Password" type="password"
                       onChange={e => setPassword(e.target.value)}/>
                </div>                                    
            </fieldset>
        </form>

          <p><button hidden class="profilebutton" onClick={() => chatgo(false)}>View Options</button></p>
      </div>
    </div>        
</div>

<div class="visitorBenefits uk-container">
  <img src="https://img.pngio.com/connected-cloud-computing-people-internet-business-connecting-people-connect-png-512_512.png" style={{"width" : "5%", "paddingTop" : "7%"}}/>
  <p class="visitorbenefit-title">How It Works</p>
  <div class="uk-grid"> 
    <div class="visitorBenefit1 uk-width-medium-1-3">
    <img class="visitorbenefit-headerimage" src="IntroBenefit1a.png"/>
        <div class="visitorbenefit-header">Continue with Google</div>
        <div class="visitorbenefit-sub uk-width-large-4-6 uk-container-center">Use your Google account for security and convenience.</div>
    </div>
    <div class="visitorBenefit2 uk-width-medium-1-3">
        <img class="visitorbenefit-headerimage" src="IntroBenefit2a.png"/>
        <div class="visitorbenefit-header">Chat to Arrange a Call</div>
        <div class="visitorbenefit-sub uk-width-large-4-6 uk-container-center">We'll give you a two-way chat window so you can arrange the call together.</div>
    </div>
    <div class="visitorBenefit3 uk-width-medium-1-3">
        <img class="visitorbenefit-headerimage" src="IntroBenefit3a.png"/>                
        <div class="visitorbenefit-header">Get Advice in Minutes</div>
        <div class="visitorbenefit-sub uk-width-large-4-6 uk-container-center">Get advice on a call.  Pay only for the minutes you need.  There's nothing to install and no hidden fees.</div>
    </div>                
  </div>
  <p class="visitorfooter1">Support questions?  Please e-mail us at support@avyzr.com.</p>
  <br/>
  <p class="visitorfooter2">Copyright © 2021 Avyzr, Inc.  All rights reserved.</p>
</div>
    
<div class="w3-row-padding w3-center w3-margin-top benefitcontainer" className="benefitContainer" hidden>

        <div class="w3-card w3-container benefitcard" className="benefitcard">
      <img src="https://img.pngio.com/connected-cloud-computing-people-internet-business-connecting-people-connect-png-512_512.png" style={{"width" : "5%"}}/>
      <div class="cardtitle">Avyzr is the Award-Winning Solution for <b>Advice Anytime, Anywhere</b></div><br/>
  <i class="fa fa-desktop w3-margin-bottom w3-text-theme" style={{fontSize: 120}}></i>
  <div class="benefit" className="benefit">In-browser, <b>no install</b> technology</div>
  <div class="benefit" className="benefit">Pay only for the time <b>you want</b></div>
  <div class="benefit" className="benefit"><b>Safe and reliable</b> for both parties</div>
        </div>
    </div>
</div>
</div>
                    
                );                  
        }
        else {
            console.log('!lastEntryID');
            return ( 
                <div></div>
            );
        }
}

export default ProfilePage;