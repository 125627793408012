import React from 'react';
import "./TopBar.sass";
import {FiMoreHorizontal, FiExternalLink, FiSettings, FiHome, FiPlusCircle, FiMenu, FiSmile, FiCpu} from 'react-icons/fi';
import Picture from "../../../components/Picture";
import {useGlobal} from "reactn";
import {useHistory, useLocation} from "react-router-dom";
import { useToasts } from 'react-toast-notifications';
import getMeetingRoom from "../../../actions/getMeetingRoom";
import {useSelector} from "react-redux";
import setAuthToken from "../../../actions/setAuthToken";

import { setCookie, getCookie } from "../../../utils.js"


const TopBar = () => {
    const onlineUsers = useSelector(state => state.io.onlineUsers);
    const [nav, setNav] = useGlobal('nav');
    const [token, setToken] = useGlobal('token');
    const setPanel = useGlobal('panel')[1];
    const setOver = useGlobal('over')[1];
    const [user, setUser] = useGlobal('user');
    const setAudio = useGlobal('audio')[1];
    const setVideo = useGlobal('video')[1];
    const setCallDirection = useGlobal('callDirection')[1];

    const aboutUs = () => window.open("https://www.avyzr.com/about", "_blank");
//    const honeyside = () => window.open("https://www.honeyside.it", "_blank");
//    const codeCanyon = () => window.open("https://codecanyon.net/item/clover-realtime-messaging-audio-video-conferencing-web-app-nodejs-react-webrtc-socketio/25737452", "_blank");

    const history = useHistory();
    const location = useLocation();
    const { addToast } = useToasts();

    const [firstEntryPath, setFirstEntryPath] = useGlobal('firstEntryPath');


    const logout = async () => {
        const username = user.username;
        localStorage.removeItem('token');
        localStorage.removeItem('pw');
        setCookie('token','',0);
        setCookie('pw','',0);
        setCookie('email','',0);
        setCookie('user','',0);        
        
        await setToken(null);
        await setAuthToken(null);
        await setUser({});
        addToast(`User ${username} logged out!`, {
            appearance: 'success',
            autoDismiss: true,
        })
        history.replace("/login");
    };

    const errorToast = content => {
        addToast(content, {
            appearance: 'error',
            autoDismiss: true,
        })
    };

    const newMeeting = async () => {
        await setAudio(true);
        await setVideo(true);
        await setCallDirection('meeting');
        try {
            const res = await getMeetingRoom();
            history.replace('/meeting/' + res.data._id);
        }
        catch (e) {
            errorToast('Server error. Unable to initiate call.');
        }
    };

    const getStatus = () => {
        if (onlineUsers.filter(u => u.id === user.id && u.status === 'busy').length > 0) return 'busy';
        if (onlineUsers.filter(u => u.id === user.id && u.status === 'online').length > 0) return 'online';
        if (onlineUsers.filter(u => u.id === user.id && u.status === 'away').length > 0) return 'away';
        return null;
    };

    return (
            <div className="top-bar uk-flex uk-flex-between uk-flex-middle">
                <div className="uk-flex uk-flex-middle">
                        <img className="topbarlogo" src="AVYZR-logo-horizontal-small.png" onClick={() => {
                                history.replace(firstEntryPath);
                           }}/>
                </div>
                <div className="nav">
                    <div className="uk-inline">
                        <div className="button" type="button">
                            <FiMenu/>
                        </div>
                        <div className="sidemenu" data-uk-dropdown="mode: click; offset: -5; boundary: .top-bar">
                            <div className="link menuname" style={{ display: (token && user) ? "block" : "none"}}>{user.username}</div>
                            <div className="divider" style={{ display: (token && user) ? "block" : "none"}}/>

                            <div className="link" onClick={aboutUs}>About Avyzr <div className="icon"><FiExternalLink/></div></div>
                            {(user.level === 'root' || user.level === 'admin') && <div className="divider"/>}
                            {(user.level === 'root' || user.level === 'admin') && <div className="link" onClick={() => {
                                setOver(true);
                                history.replace('/admin');
                            }}>Admin Panel</div>}
                            <div className="link" style={{ display: (token) ? "block" : "none"}} onClick={logout}>Logout</div>
                        </div>
                    </div>
                </div>
            </div>
    );
}

export default TopBar;
