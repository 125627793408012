import React from 'react';
import "./TopBar.sass";
import {FiMoreHorizontal, FiExternalLink, FiChevronLeft, FiSettings, FiPhone, FiVideo, FiHome, FiPlusCircle, FiMenu, FiSmile, FiCpu} from 'react-icons/fi';
import Picture from "../../../components/Picture";
import {useGlobal} from "reactn";
import {useHistory, useLocation} from "react-router-dom";
import { useToasts } from 'react-toast-notifications';
import getMeetingRoom from "../../../actions/getMeetingRoom";
import {useSelector} from "react-redux";
import { setCookie, getCookie } from "../../../utils.js"

import postCall from "../../../actions/postCall";
import Actions from "../../../constants/Actions";
import moment from "moment";
import {useDispatch} from "react-redux";

import axios from "axios";
import Config from "../../../config";

const TopBar = () => {
    const onlineUsers = useSelector(state => state.io.onlineUsers);
    const [nav, setNav] = useGlobal('nav');
    const setToken = useGlobal('token')[1];
    const setPanel = useGlobal('panel')[1];
    const setOver = useGlobal('over')[1];
    const [user, setUser] = useGlobal('user');
    const setAudio = useGlobal('audio')[1];
    const setVideo = useGlobal('video')[1];
    const setCallDirection = useGlobal('callDirection')[1];
    const [firstEntryPath, setFirstEntryPath] = useGlobal('firstEntryPath');    

    const aboutUs = () => window.open("https://www.avyzr.com/about", "_blank");

    const room = useSelector(state => state.io.room) || {};
    const [meeting, setMeeting] = useGlobal('meeting');
    const dispatch = useDispatch();    
    let other = {};

    room.people && room.people.forEach(person => {
        if (user.id !== person._id) other = person;
    });

    if (!other.firstName) {
        other = {...other, firstName: 'Deleted', lastName: 'User'};
    }

    console.log('Panel topbar ',other,meeting,room);

    const history = useHistory();
    const location = useLocation();
    const { addToast } = useToasts();

    const logout = async () => {
        const username = user.username;
        localStorage.removeItem('token');
        setCookie('token','',0);
        setCookie('pw','',0);
        setCookie('email','',0);
        setCookie('user','',0);
        await setToken(null);
        await setUser({});
/*        addToast(`User ${username} logged out!`, {
            appearance: 'success',
            autoDismiss: true,
        })
*/
        history.replace("/login");
    };

    const warningToast = content => {
        addToast(content, {
            appearance: 'warning',
            autoDismiss: true,
        })
    };

    const errorToast = content => {
        addToast(content, {
            appearance: 'error',
            autoDismiss: true,
        })
    };

    const newMeeting = async () => {
        await setAudio(true);
        await setVideo(true);
        await setCallDirection('meeting');
        try {
            const res = await getMeetingRoom();
            history.replace('/meeting/' + res.data._id);
        }
        catch (e) {
            errorToast('Server error. Unable to initiate call.');
        }
    };
    
    const call = async isVideo => {
        if (onlineUsers.filter(u => u.id === other._id).length === 0 && !room.isGroup)
            return warningToast(other.firstName + ' is not ready yet.');

        const confirmationMessage = 'Your call with ' + other.firstName + ' ' + other.lastName + ' will begin momentarily.  Your card will be charged $' + (other.price1 / 100) + ' per minute with a minimum of ' + other.minutes1 + ' minutes.  By continuing, you agree to the terms of use.';

        if (!window.confirm(confirmationMessage))
                return;

        // APPLY INITIAL PAYMENT
           try {
              const response = await axios.post(
                (Config.url || '') + "/api/stripe/charge",
                {
                  amount: other.minutes1 * other.price1,
                  email: user.email,
                  userid: user.username,
                  desc: other.username,
                }
              );

              console.log("Stripe charge | data", response.data);

              if (response.data.success) {
                console.log("Stripe charge | payment successful!");
              }
            } catch (error) {
              console.log("Stripe charge | ", error);
            }

        await setAudio(true);
        await setVideo(isVideo);
        await setCallDirection('outgoing');
        dispatch({type: Actions.RTC_SET_COUNTERPART, counterpart: other});
        try {
            const res = await getMeetingRoom({startedAsCall: true, caller: user.id, callee: other._id, callToGroup: room.isGroup, group: room._id});
            await setMeeting(res.data);
            history.replace('/meeting/' + res.data._id);
            await postCall({roomID: room._id, meetingID: res.data._id});
        }
        catch (e) {
            errorToast('Server error. Unable to initiate call.');
        }
    };

    const getStatus = () => {
        if (onlineUsers.filter(u => u.id === user.id && u.status === 'busy').length > 0) return 'busy';
        if (onlineUsers.filter(u => u.id === user.id && u.status === 'online').length > 0) return 'online';
        if (onlineUsers.filter(u => u.id === user.id && u.status === 'away').length > 0) return 'away';
        return null;
    };

    const regularBarLeft = () => {
        return (
        <div className="uk-flex uk-flex-middle">
                <img className="topbarlogo" src="AVYZR-logo-horizontal-small.png" />
        </div>
        );
    }

    const chatBarLeft = () => {
        const title = other.firstName + ' ' + other.lastName;

        return (
        <div className="nav uk-flex uk-flex-middle">
            <div className="button mobile backbutton" onClick={back}>
                <FiChevronLeft/>
            </div>
            <div className="chatname-wrapper">
                <div className="chatname">{title}{title.length > 22 && '...'}</div>
            </div>
        </div>    
        );
    }

    const back = async () => {
        dispatch({type: Actions.SET_ROOM, room: null});
        dispatch({type: Actions.SET_MESSAGES, messages: []}); 
        
        if(user.price1)
            setOver(false);
        else
            await logout();

//        if(window.preChatPage)
//            history.replace(window.preChatPage);
//        else

            console.log('back with firstEntryPath = ',firstEntryPath);

            history.replace((firstEntryPath && firstEntryPath.startsWith("/u/")) ? firstEntryPath : "/");
            window.location = "https://avyzr.com" + firstEntryPath;
    }

    const SideMenu = () => {
        return (
            <div className="uk-inline" style={{"width":"100%"}}>
                <div className="button" type="button">
                    <FiMenu/>
                </div>
                <div className="sidemenu" data-uk-dropdown="mode: click; offset: -5; boundary: .top-bar">
                    <div className="divider" hidden/>
                    <div className="link" hidden onClick={aboutUs}>About Avyzr <div className="icon"><FiExternalLink/></div></div>
                    {(user.level === 'root' || user.level === 'admin') && <div className="divider"/>}
                    {(user.level === 'root' || user.level === 'admin') && <div className="link" onClick={() => {
                        setOver(true);
                        history.replace('/admin');
                    }}>Admin Panel</div>}
                    <div className="divider"/>
                    <div className="link" onClick={logout}>Logout</div>
                </div>
            </div>
        );
    }

    const StartCallButton = () => {
        return (
            <div className="uk-inline" style={{ display: (firstEntryPath && firstEntryPath.startsWith("/u/")) ? "block" : "none"}}>
                <div className="startcallbutton" onClick={() => call(false)} type="button">
                    <FiPhone hidden/>
                    Start Call 
                </div>
            </div>
        );
    }

/* OLD CODE */
/*
                    <div className="button" onClick={() => call(true)} style={{ display: (room && room.people) ? "block" : "none"}}>
                        <FiVideo/>
                    </div>
                    <div className="button" onClick={() => call(false)} style={{ display: (room && room.people) ? "block" : "none"}}>
                        <FiPhone/>
                    </div>
*/



    return (
            <div className="top-bar paneltopbar uk-flex uk-flex-between uk-flex-middle">
                {room.people ? chatBarLeft() : regularBarLeft()}
                <div className="nav">
                    {(user.level === 'root' || user.level === 'admin') &&
                        <div className={`button${location.pathname.startsWith('/admin') ? ' active' : ''}`} onClick={() => {
                            setOver(true);
                            history.replace('/admin');
                        }}>
                            <FiCpu/>
                        </div>
                    }
                    
                {room.people ? StartCallButton() : SideMenu()}

                </div>
            </div>
    );
}

export default TopBar;
