import React from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";
import Config from "../../config";
import {useGlobal} from 'reactn';

export const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const CARD_OPTIONS = {
    iconStyle: 'solid',
    style: {
      base: {
        iconColor: '#c4f0ff',
        color: '#fff',
        fontWeight: 500,
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {color: '#fce883'},
        '::placeholder': {color: '#9df'},
      },
      invalid: {
        iconColor: '#ffc7ee',
        color: '#ffc7ee',
      },
    },
  };

  const [user, setUser] = useGlobal('user');

/*
  <fieldset className="FormGroup">
    <div className="FormRow">
      <CardElement options={CARD_OPTIONS} onChange={onChange} />
    </div>
  </fieldset>
*/

  const CardField = ({onChange}) => (
        <CardElement className="StripePaymentCardField" options={CARD_OPTIONS} onChange={onChange} />
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    var customerID = '';

    if(error) {
      console.log('Error creating paymentMethod:', error);
      return;
    }

    try {
      const response = await axios.post(
        (Config.url || '') + "/api/stripe/charge",
        {
          amount: -1,
          paymentMethodID: paymentMethod.id,
          email: user.email,
          userid: user.username,
          desc: 'Sign Up from ' + window.location.pathname,
        }
      );

      console.log("Stripe 35 | data", response.data);
      props.onSuccessHandler(response.data);
      if (response.data.success) {
        console.log("CheckoutForm.js 25 | Create setupIntent successful!");

      customerID = response.data.customerID;

      await stripe.confirmCardSetup(
        response.data.client_secret,
        { payment_method: paymentMethod.id }).then(async(result) => {
          if(result.error) {
            console.log('confirmCardSetup error:',result.error);
            return;
          }
          else {
            console.log('confirmCardSetup completed:',result.setupIntent);

            try {
              const { id } = paymentMethod;
              const response = await axios.post(
                (Config.url || '') + "/api/stripe/charge",
                {
                  amount: 0,
                  paymentMethodID: paymentMethod.id,
                  customerID: customerID,
                  email: user.email,
                  userid: user.username,
                }
              );

              console.log("Stripe 35 | data", response.data);
              props.onSuccessHandler(response.data);
              if (response.data.success) {
                console.log("CheckoutForm.js 25 | payment successful!");
              }
            } catch (error) {
              console.log("CheckoutForm.js 28 | ", error);
            }


            return;
          }
        });

      }
    } catch (error) {
      console.log("CheckoutForm.js 28 | ", error);
    }

    return;

    if (!error) {
      console.log("Stripe 23 | token generated!", paymentMethod);
      try {
        const { id } = paymentMethod;
        const response = await axios.post(
          (Config.url || '') + "/api/stripe/charge",
          {
            amount: 0,
            id: paymentMethod.id,
            email: user.email,
            userid: user.username,
          }
        );

        console.log("Stripe 35 | data", response.data);
        props.onSuccessHandler(response.data);
        if (response.data.success) {
          console.log("CheckoutForm.js 25 | payment successful!");
        }
      } catch (error) {
        console.log("CheckoutForm.js 28 | ", error);
      }
    } else {
      console.log(error.message);
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ maxWidth: 1000 }}>
      <CardField />
      <button className="StripePaymentCardButton">Save and Continue</button>
    </form>
  );
};