import React, {useEffect, useState} from 'react';
import {useGlobal} from 'reactn';
import Credits from "./components/Credits";
import Logo from "./components/Logo";
import Input from "./components/Input";
import './Login.sass';
import Div100vh from "react-div-100vh";
import Config from "../../config";
import login from "../../actions/login";
import register from "../../actions/register";
import {useHistory} from "react-router-dom";
import jwtDecode from "jwt-decode";
import setAuthToken from "../../actions/setAuthToken";
import {useDispatch} from "react-redux";
import initIO from "../../actions/initIO";

import { setCookie, getCookie } from "../../utils.js"
import TopBar from "./components/TopBar";
import ReactGA from 'react-ga';

import axios from "axios";

import Swiper from 'react-id-swiper';

//import { Form, Field } from 'react-advanced-form'
//import { Input, Button } from 'react-advanced-form-addons'

//import { Steps, Step } from "react-step-builder";

//import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { mixed, number, object } from 'yup';
import "yup-phone";

//import Button from '@material-ui/core/Button';
//import TextField from '@material-ui/core/TextField';

import { Box, Button, Card, CardContent, CircularProgress, Grid, Step, StepLabel, Stepper } from '@material-ui/core';
import { ErrorMessage, Field, Form, Formik, FormikConfig, FormikValues } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';

const registerUser = () => {
    return;
}

const onSubmitStart = () => {
    return;
}

// Wizard is a single Formik instance whose children are each page of the
// multi-step form. The form is submitted on each forward transition (can only
// progress with valid input), whereas a backwards step is allowed with
// incomplete data. A snapshot of form state is used as initialValues after each
// transition. Each page has an optional submit handler, and the top-level
// submit is called when the final page is submitted.
//const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

/* OLD
const Wizard = ({ children, initialValues, onSubmit }) => {
  const [stepNumber, setStepNumber] = useState(0);
  const steps = React.Children.toArray(children);
  const [snapshot, setSnapshot] = useState(initialValues);

  const step = steps[stepNumber];
  const totalSteps = steps.length;
  const isLastStep = stepNumber === totalSteps - 1;

  const next = values => {
    setSnapshot(values);
    setStepNumber(Math.min(stepNumber + 1, totalSteps - 1));
  };

  const previous = values => {
    setSnapshot(values);
    setStepNumber(Math.max(stepNumber - 1, 0));
  };

  const handleSubmit = async (values, bag) => {
    if (step.props.onSubmit) {
      await step.props.onSubmit(values, bag);
    }
    if (isLastStep) {
      return onSubmit(values, bag);
    } else {
      bag.setTouched({});
      next(values);
    }
  };

  return (
    <Formik
      initialValues={snapshot}
      onSubmit={handleSubmit}
      validationSchema={step.props.validationSchema}
    >
      {formik => (
        <Form>
          <p>
            Step {stepNumber + 1} of {totalSteps}
          </p>
          {step}
          <div style={{ display: 'flex' }}>
            {stepNumber > 0 && (
              <button onClick={() => previous(formik.values)} type="button">
                Back
              </button>
            )}
            <div>
              <button disabled={formik.isSubmitting} type="submit">
                {isLastStep ? 'Submit' : 'Next'}
              </button>
            </div>
          </div>

        </Form>
      )}
    </Formik>
  );
};

const WizardStep = ({ children }) => children;

const RegForm = () => {
    return (  
    <div>
    <Wizard
      initialValues={{
        email: '',
        firstName: '',
        lastName: '',
      }}
      onSubmit={async values =>
        sleep(300).then(() => console.log('Wizard submit', values))
      }
    >
      <WizardStep
        onSubmit={() => console.log('Step1 onSubmit')}
        validationSchema={Yup.object({
          firstName: Yup.string().required('required'),
          lastName: Yup.string().required('required'),
        })}
      >

        <div>
          <p className="registerform-title">OK, let's get started.  Tell us a little bit about yourself.</p>        
          <label htmlFor="firstName">First Name</label>
          <Field
            autoComplete="given-name"
            component="input"
            id="firstName"
            name="firstName"
            placeholder="First Name"
            type="text"
          />
          <ErrorMessage className="error" component="div" name="firstName" />
        </div>
        <div>
          <label htmlFor="lastName">Last Name</label>
          <Field
            autoComplete="family-name"
            component="input"
            id="lastName"
            name="lastName"
            placeholder="Last Name"
            type="text"
          />
          <ErrorMessage className="error" component="div" name="lastName" />
        </div>
      </WizardStep>
      <WizardStep
        onSubmit={() => console.log('Step2 onSubmit')}
        validationSchema={Yup.object({
          email: Yup.string()
            .email('Invalid email address')
            .required('required'),
        })}
      >
        <div>
          <label htmlFor="email">Email</label>
          <Field
            autoComplete="email"
            component="input"
            id="email"
            name="email"
            placeholder="Email"
            type="text"
          />
          <ErrorMessage className="error" component="div" name="email" />
        </div>
      </WizardStep>
    </Wizard>
  </div>
  );    
}

*/

const ExpertIntroSlider = () => {
   const params = {
      effect: 'flip',
      grabCursor: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },

    }

if (1)
    return (
      <Swiper {...params}>
        <div className="elevatorpitch-wrapper">
        <img className="elevatorpitch-image" src="IntroBackground6c.jpg" />
        <div className="elevatorpitch">
            Earn Money Giving Advice - Anytime, Anywhere</div>
            <div className="elevatorpitch-sub">
            People are willing to pay for your advice.  Help them while making money.  We take care of the logistics for both parties.</div>
            <div className="startButtonWrapper">
                <button class="startbutton" data-uk-toggle="target: .introWrapper, .login;animation: uk-animation-fade">Create Your Free Account</button>
            </div>
        </div>
      </Swiper>
    );
/*  else
    return (
        <div className="elevatorpitch-wrapper">
        <img className="elevatorpitch-image" src="IntroBackground2d.jpg" />
        <div className="elevatorpitch">
            Get Started in 2 Minutes, for Free</div>
            <div className="elevatorpitch-sub">
            There's nothing to install or set up.  And, there's no cost to get started.  We don't even ask for a credit card or billing info.</div>
        </div>
        <div className="elevatorpitch-wrapper">
        <img className="elevatorpitch-image" src="IntroBackground3d.jpg" />        
        <div className="elevatorpitch">
            We Make It Fun and Easy</div>
            <div className="elevatorpitch-sub">
            All you have to do is share your unique link and answer calls for advice.  We'll take care of the rest.</div>
        </div>
    );
*/
  };

const Login = () => {
    const dispatch = useDispatch();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [keep, setKeep] = useState(true);
    const [loginErrors, setLoginErrors] = useState({});

    const [registerUsername, setRegisterUsername] = useState('');
    const [registerEmail, setRegisterEmail] = useState('');
    const [registerPhone, setRegisterPhone] = useState('');    
    const [registerFirstName, setRegisterFirstName] = useState('');
    const [registerLastName, setRegisterLastName] = useState('');
    const [registerPassword, setRegisterPassword] = useState('');
    const [registerRepeatPassword, setRegisterRepeatPassword] = useState('');
    const [registerErrors, setRegisterErrors] = useState({});

    const setToken = useGlobal('token')[1];
    const setUser = useGlobal('user')[1];
    const [entryPath, setEntryPath] = useGlobal('entryPath');
    
    const history = useHistory();

    const onLogin = async e => {
        e.preventDefault();
        try {
            const res = await login(email, password);

            if(keep) {
                console.log('onLogin | Keep = True; Storing everything in cookies');            
                setCookie('pw',password,365);
                setCookie('email',email,365);
                setCookie('token',res.data.token,365);
                setCookie('user',JSON.stringify(jwtDecode(res.data.token)),365);
            }
        
            setLoginErrors({});
            setAuthToken(res.data.token);
            const loginUser = jwtDecode(res.data.token);
            setUser(loginUser);
            setToken(res.data.token);
            dispatch(initIO(res.data.token));
            console.log('login.js onLogin set user to',jwtDecode(res.data.token));
            
            ReactGA.event({
              category: 'User',
              action: 'Login',
              label: loginUser.username,
            });
/*
          if(!loginUser.stripeAccountID) {

              try {
                const response = await axios.post(
                  (Config.url || '') + "/api/stripe/charge",
                  {
                    amount: -2,
                    email: loginUser.email,
                    userid: loginUser.username,
                  }
                );

                console.log("Stripe 35 | data", response.data);
                if (response.data.success) {
                  console.log("Stripe Account Creation  |  successful!");
                  window.location.replace(response.data.stripeAccountLinkURL);
                }
              } catch (error) {
                console.log("Stripe Account Creation | ", error);

              }
            return;
         }

          try {
            const response = await axios.post(
              (Config.url || '') + "/api/stripe/charge",
              {
                amount: -3,
                email: loginUser.email,
                userid: loginUser.username,
              }
            );

            console.log("Stripe 35 | data", response.data);
            if (response.data.success) {
              console.log("Stripe Account Retrieve  |  successful!");
            }
          } catch (error) {
            console.log("Stripe Account Retrieve | ", error);

          }
*/
            history.replace(['/login', '/'].includes(entryPath) ? '/' : entryPath);
            await setEntryPath(null);
        }
        catch (e) {
            let errors = {};
            if (!e.response || typeof e.response.data !== 'object') errors.generic = 'Could not connect to server.';
            else errors = e.response.data;
            setLoginErrors(errors);
        }
    };

    const onRegister1 = async e => {

        return;
    };


    /* FORMIK MULTISTEP EXAMPLE FROM YOUTUBE */

    const sleep = (time) => new Promise((acc) => setTimeout(acc, time));

    function TestHome() {
        return (<Card style={{margin: 'auto', borderRadius: '4px', textAlign: 'left'}}>
          <CardContent>
            <FormikStepper initialValues={{
            firstName: '',
            lastName: '',
            millionaire: false,
            money: 0,
            tagLine: 'Here to help and available most days 8am - 8pm',
            price1: 3.99,
            minutes1: 4,
        }} onSubmit={async (values, {setSubmitting, setErrors, setFieldError, setStatus, resetForm}) => {  
    //        await sleep(3000);
            console.log('values', values);
            console.log('e-mail=', values.eMail);
            console.log('password=', values.password);
            console.log('name=', values.firstName, values.lastName);

            console.log('onSubmit with ',values);

            var finalEmail = values.eMail;
            var finalPassword = values.password;
            
            await register({
                username: values.username,
                email: values.eMail,
                firstName: values.firstName,
                lastName: values.lastName,
                password: values.password,
                repeatPassword: values.password,
                phoneNumber: values.phoneNumber,
                price1: (values.price1*100),
                minutes1: values.minutes1,
                tagLine: values.tagLine,
            }).then( async () => {

                console.log("Register successful!");

                setStatus({success: true})

                const res = await login(finalEmail, finalPassword);
                setRegisterErrors({});            
                
                if(keep) {
                    console.log('onRegister | Keep = True; Storing everything in cookies');            
                    setCookie('pw',password,365);
                    setCookie('email',email,365);
                    setCookie('token',res.data.token,365);
                    setCookie('user',JSON.stringify(jwtDecode(res.data.token)),365);
                }
                
                ReactGA.event({
                  category: 'User',
                  action: 'Register',
                  label: values.username
                });

                setAuthToken(res.data.token);
                setUser(jwtDecode(res.data.token));
                setToken(res.data.token);
                dispatch(initIO(res.data.token));

            }).catch(e => {
                setFieldError('general', e.message);

                setStatus({success: false});

                let errors = {};
                if (!e.response || typeof e.response.data !== 'object') errors.generic = 'Could not connect to server.';
                else errors = e.response.data;
                setRegisterErrors(errors);
                setErrors({submit: e.message})

                if(errors.username)
                    alert('That username is already taken.  Please enter a different one.');
                else if(errors.email)
                    alert('That e-mail address is already in use.  Please click Login at the top to login to your existing account.');
            })
            .finally(() => {
                setSubmitting(false);
            })

        }}>

              <FormikStep label="Notifications" validationSchema={object({
                phoneNumber: Yup.string()
                 .phone()
                 .required('Required'),
              })}>
                <p className="registerform-title1">Enter your phone number and we'll text you a validation code to continue.</p>
                <p className="registerform-title2" hidden>Remember, we don't need you to install anything or give us any billing info, so this is the only way for us to notify you.</p>

                <Box paddingBottom={2}>
                  <Field fullWidth name="phoneNumber" type="string" component={TextField} label="Cell Phone # (e.g. 3235551234)"/>
                </Box>
              </FormikStep>

              <FormikStep label="Validate" validationSchema={object({
                verificationCode: Yup.string()
                 .required('Required'),
              })}>
                <p className="registerform-title1">Please enter the code we just texted you.</p>

                <Box paddingBottom={2}>
                  <Field fullWidth name="verificationCode" type="string" component={TextField} label="Verification Code"/>
                </Box>
              </FormikStep>

    
            <FormikStep label="Account" validationSchema={object({
               eMail: Yup.string().email('Invalid email').required('Required')
/*                .test('checkEmailTaken', 'That e-mail address is already registered. Please login or enter a different one.', function (value) {

                    return new Promise((resolve, reject) => {

                        register({
                            email: value,
                            loginType: 'search',
                            firstName: 'test',
                            lastName: 'test',
                            password: 'test',
                            repeatPassword: 'test'
                        }).then(() => { 
                            // exists
                            resolve(false)
                        }).catch(() => {
                            // not exists
                            resolve(true)
                        })
                    })}) */ ,
                password: Yup
                  .string()
                  .min(8)
                  .required(),
/*                confirmPassword: Yup
                  .string()
                  .required()
                  .oneOf(
                  [Yup.ref('password'), null],
                 'Passwords must match'),*/
            })}>

                <p className="registerform-title1">Please enter your e-mail address and a password to create your account.</p>

                <Box paddingBottom={2}>
                  <Field fullWidth name="eMail" component={TextField} label="Enter your e-mail address"/>
                </Box>            
                <Box paddingBottom={2}>
                  <Field fullWidth name="password" component={TextField} label="Choose a password"/>
                </Box>            
                <Box paddingBottom={2} hidden>
                  <Field fullWidth name="confirmPassword" component={TextField} label="Enter password again to confirm"/>
                </Box>            
                <Box paddingBottom={2}>
                  <Field name="millionaire" type="checkbox" component={CheckboxWithLabel} checked Label={{ label: 'I accept the Terms of Use.' }}/>
                </Box>
              </FormikStep>
              <FormikStep label="Welcome" >
                <h3 className="welcomeform-title1">Welcome to Avyzr!</h3>
                <p className="welcomeform-title2">Just one more step: setting up your profile. This is what others will see when they click your link. You can change it later, but we need a starting point.</p>
              </FormikStep>
              <FormikStep label="Profile" validationSchema={object({
                  firstName: Yup.string()
                     .min(2, 'Too Short')
                     .max(25, 'Too Long')
                     .required('Required'),
                   lastName: Yup.string()
                     .min(2, 'Too Short')
                     .max(25, 'Too Long')
                     .required('Required'),
                   username: Yup.string()
                     .min(6, 'Too Short')
                     .max(25, 'Too Long')
                     .required('Required')
/*                    .test('checkUsernameTaken', 'That username is already registered. Please choose a different one.', function (value) {
                    return new Promise((resolve, reject) => {

                        register({
                            username: value,
                            loginType: 'search',
                            firstName: 'test',
                            lastName: 'test',
                            password: 'test',
                            repeatPassword: 'test'
                        }).then(() => { 
                            // exists
                            resolve(false)
                        }).catch(() => {
                            // not exists
                            resolve(true)
                        })
                    })}) */,
                   tagLine: Yup.string()
                     .min(10, 'Too Short')
                     .max(120, 'Too Long')
                     .required('Required'),
                  price1: Yup
                    .number("Must be a number")
                    .required("Required")
                    .positive()
                    .min(0.99, "Min is 0.99")
                    .max(19.99, "Max is 19.99")
                  .test(
                      "maxDigitsAfterDecimal",
                      "Please enter a valid price in US Dollars (e.g. 3.99)",
                      (number) => /^\d+(\.\d{1,2})?$/.test(number)
                    ),
                  minutes1: Yup
                    .number("Must be a number")
                    .required("Required")
                    .positive()
                    .integer()
                    .min(1, "Min is 1")
                    .max(20, "Max is 20")                    
            })}>

              
                <Box paddingBottom={2}>
                  <Field fullWidth name="firstName" component={TextField} label="First Name"/>
                </Box>
                <Box paddingBottom={2}>
                  <Field fullWidth name="lastName" component={TextField} label="Last Name"/>
                </Box>          
                <Box paddingBottom={2}>
                  <Field fullWidth name="tagLine" component={TextField} label="Tag Line"/>
                </Box>
                <p className="registerform-title3" hidden>We recommend starting with a price per minute of $3.99 and a minimum of 4 minutes.  You can change these later.</p>            
                <Box paddingBottom={2}>
                  <Field fullWidth name="price1" component={TextField} label="Price per minute (dollars)"/>
                </Box>
                <Box paddingBottom={2}>
                  <Field fullWidth name="minutes1" type="number" component={TextField} label="Minimum call length (minutes)"/>
                </Box>
                <p className="registerform-title3">Your username determines your unique link. It will show as http://avyzr.com/u/UserName.</p>
                <Box paddingBottom={2}>
                  <Field fullWidth name="username" component={TextField} label="Choose a username"/>
                </Box>
              </FormikStep>
            </FormikStepper>
          </CardContent>
        </Card>);
    }
    function FormikStep({ children }) {
        return <>{children}</>;
    }
    function FormikStepper({ children, ...props }) {
        const childrenArray = React.Children.toArray(children);
        const [step, setStep] = useState(0);
        const currentChild = childrenArray[step];
        const [completed, setCompleted] = useState(false);
        function isLastStep() {
            return step === childrenArray.length - 1;
        }
        return (<Formik {...props} validationSchema={currentChild.props.validationSchema} onSubmit={async (values, helpers) => {
            console.log('Formik onSubmit at step = ',step, ' with values = ',values);
            console.log('Helpers = ',helpers);
            console.log('Props = ',props);

            if(step == 0) {
                register({
                    phoneNumber: values.phoneNumber,
                    loginType: 'validatePhone',
                    firstName: 'test',
                    lastName: 'test',
                    password: 'test',
                    repeatPassword: 'test'
                }).then(() => { 
                    // exists
                      setStep(1);
//                    alert('That e-mail has already been taken. Please login or enter a new one.');
                    return;
                }).catch(() => {
                    // not exists
                    setStep(1);
                    return;
                })                
                
            }
            else if (step == 1) {
                register({
                    phoneNumber: values.phoneNumber,
                    loginType: 'validatePhone2',
                    firstName: 'test',
                    lastName: 'test',
                    password: values.verificationCode,
                    repeatPassword: 'test'
                }).then(res => { 
                    // exists
                    
                    console.log("Res=",res);
                    if(!res.data || res.data.status != "approved")
                      alert("Verification failed.  Please try again.");
                    else
                      setStep(2);
//                    alert('That e-mail has already been taken. Please login or enter a new one.');
                    return;
                }).catch(() => {
                    // not exists
                    alert("Verification failed.  Please try again.");

                    return;
                })                
                
            }
            else if(step == 2) {
                register({
                    email: values.eMail,
                    loginType: 'search',
                    firstName: 'test',
                    lastName: 'test',
                    password: 'test',
                    repeatPassword: 'test'
                }).then(() => { 
                    // exists

                    alert('That e-mail has already been taken. Please login or enter a new one.');
                    return;
                }).catch(() => {
                    // not exists
                    setStep((s) => s + 1);
                    return;
                })                
            }
            else if(step == 3) {
                if(!isLastStep())
                    setStep((s) => s + 1);
            }
            else if(step == 4) {
                register({
                    username: values.username,
                    loginType: 'search',
                    firstName: 'test',
                    lastName: 'test',
                    password: 'test',
                    repeatPassword: 'test'
                }).then(() => { 
                    // exists
                    alert('That username has already been taken. Please select a different one.');
                    return;
                }).catch(() => {
                    // not exists
                    if(!isLastStep())
                        setStep((s) => s + 1);
                    else {
                      props.onSubmit(values, helpers).then(() => {setCompleted(true);});
                    }
                    return;
                })                
            }
            else if(!isLastStep()) {
                setStep((s) => s + 1);                
            }

            else if (isLastStep()) {
                props.onSubmit(values, helpers).then(() => {setCompleted(true);})
                
            }

        }}>
          {({ isSubmitting }) => (<Form autoComplete="off">
              <Stepper alternativeLabel activeStep={step}>
                {childrenArray.map((child, index) => (<Step key={child.props.label} completed={step > index || completed}>
                    <StepLabel>{child.props.label}</StepLabel>
                  </Step>))}
              </Stepper>

              {currentChild}

              <Grid container justify="flex-end" spacing={2}>
                {step > 0 ? (<Grid item hidden>
                    <Button disabled={isSubmitting} variant="contained" color="primary" onClick={() => setStep((s) => s - 1)}>
                      Back
                    </Button>
                  </Grid>) : null}
                <Grid item>
                  <Button startIcon={isSubmitting ? <CircularProgress size="1rem"/> : null} disabled={isSubmitting} variant="contained" size="large" color="primary" type="submit">
                    {isSubmitting ? 'Submitting' : isLastStep() ? 'Complete Setup' : 'Next'}
                  </Button>
                </Grid>
              </Grid>
            </Form>)}
        </Formik>);
    }
    /* END FORMIK YOUTUBE EXAMPLE */


    const registerSubmit = async (values) => {
        console.log('registerSubmit with ',values);

        try {
            
            var finalEmail = values.eMail;
            var finalPassword = values.password;
            
            await register({
                username: values.username,
                email: values.eMail,
                firstName: values.firstName,
                lastName: values.lastName,
                password: values.password,
                repeatPassword: values.password,
                phoneNumber: values.phoneNumber,
                price1: (values.price1*100),
                minutes1: values.minutes1,
                tagLine: values.tagLine,
            });          

            const res = await login(finalEmail, finalPassword);
            setRegisterErrors({});            
            
            if(keep) {
                console.log('onRegister | Keep = True; Storing everything in cookies');            
                setCookie('pw',password,365);
                setCookie('email',email,365);
                setCookie('token',res.data.token,365);
                setCookie('user',JSON.stringify(jwtDecode(res.data.token)),365);
            }
            
            ReactGA.event({
              category: 'User',
              action: 'Register',
              label: email
            });

            setAuthToken(res.data.token);
            setUser(jwtDecode(res.data.token));
            setToken(res.data.token);
            dispatch(initIO(res.data.token));
        }
        catch (e) {
            let errors = {};
            if (!e.response || typeof e.response.data !== 'object') errors.generic = 'Could not connect to server.';
            else errors = e.response.data;
            setRegisterErrors(errors);
            if(errors.username)
                alert('That username is already taken.  Please enter a different one.');
            else if(errors.email)
                alert('That e-mail address is already in use.  Please click Login at the top to login to your existing account.');

        }
    };


    const onRegister = async e => {
        e.preventDefault();
        try {
            await register({
                username: registerUsername,
                email: registerEmail,
                firstName: registerFirstName,
                lastName: registerLastName,
                password: registerPassword,
                repeatPassword: registerPassword,
            });          

            const res = await login(registerEmail, registerPassword);
            setRegisterErrors({});            
            
            if(keep) {
                console.log('onRegister | Keep = True; Storing everything in cookies');            
                setCookie('pw',password,365);
                setCookie('email',email,365);
                setCookie('token',res.data.token,365);
                setCookie('user',JSON.stringify(jwtDecode(res.data.token)),365);
            }
            
            ReactGA.event({
              category: 'User',
              action: 'Register',
              label: email
            });

            setAuthToken(res.data.token);
            setUser(jwtDecode(res.data.token));
            setToken(res.data.token);
            dispatch(initIO(res.data.token));
        }
        catch (e) {
            let errors = {};
            if (!e.response || typeof e.response.data !== 'object') errors.generic = 'Could not connect to server.';
            else errors = e.response.data;
            setRegisterErrors(errors);
        }
    };

    const loginInfo = Object.keys(loginErrors).map(key => (
        <div className="uk-text-center" key={key}>
            <span className="uk-text-danger">{loginErrors[key]}</span>
        </div>
    ));

    const registerInfo = Object.keys(registerErrors).map(key => (
        <div className="uk-text-center" key={key}>
            <span className="uk-text-danger">{registerErrors[key]}</span>
        </div>
    ));

// after introWrapper, had: <ExpertIntroSlider />

if (1) {
        return (
        <Div100vh>
            <TopBar />        

            <div className="introWrapper">

                <div className="elevatorpitch-wrapper">
                <img className="elevatorpitch-image" src="IntroBackground5c.jpg" hidden />
                <div className="elevatorpitch-overlay">
                    <div className="elevatorpitch">
                        Earn Money Giving Advice - Anytime, Anywhere</div>
                        <div className="elevatorpitch-sub">
                        People are willing to pay for your advice.  Help them while making money.  Get started for free - no credit card required.</div>
                        <div className="startButtonWrapper">
                            <button class="startbutton" data-uk-toggle="target: .introWrapper, .introBenefits, .registerContainer;animation: uk-animation-fade">Create Your Free Account</button>
                        </div>
                    </div>
                </div>

                <div className="startButtonWrapper" hidden>
                    <button class="startbutton" data-uk-toggle="target: .introWrapper, .introBenefits, .login;animation: uk-animation-fade">Create Your Free Account</button>
                </div>
                <div className="loginButtonWrapper" hidden>
                    <button class="loginButton" data-uk-toggle="target: .introWrapper, .login;animation: uk-animation-fade">I already have an account</button>
                </div>
            </div>
            <div class="introBenefits uk-container">
              <img src="https://img.pngio.com/connected-cloud-computing-people-internet-business-connecting-people-connect-png-512_512.png" style={{"width" : "5%", "paddingTop" : "7%"}}/>
              <p class="introbenefit-title">How It Works</p>
              <div class="uk-grid"> 
                <div class="introBenefit1 uk-width-medium-1-3">
                <img class="introbenefit-headerimage" src="IntroBenefit1a.png"/>
                    <div class="introbenefit-header">Share Your Link</div>
                    <div class="introbenefit-sub uk-width-large-4-6 uk-container-center">Share your Avyzr profile link via e-mail, text, web sites, etc.</div>
                </div>
                <div class="introBenefit2 uk-width-medium-1-3">
                    <img class="introbenefit-headerimage" src="IntroBenefit2a.png"/>
                    <div class="introbenefit-header">Receive Call Requests</div>
                    <div class="introbenefit-sub uk-width-large-4-6 uk-container-center">When someone wants a call, we'll let you know.  You can answer or schedule a time.</div>
                </div>
                <div class="introBenefit3 uk-width-medium-1-3">
                    <img class="introbenefit-headerimage" src="IntroBenefit3a.png"/>                
                    <div class="introbenefit-header">Get Paid</div>
                    <div class="introbenefit-sub uk-width-large-4-6 uk-container-center">We handle all the logistics so you can focus on helping people and making money.</div>
                </div>                
              </div>
              <p class="introfooter1">Support questions?  Please e-mail us at support@avyzr.com.</p>
              <br/>
              <p class="introfooter2">Copyright © 2021 Avyzr, Inc.  All rights reserved.</p>
            </div>

            <div class="w3-row-padding w3-center w3-margin-top benefitcontainer" className="benefitContainer" hidden>
                <div class="w3-card w3-container benefitcard" className="benefitcard">
                  <img src="https://img.pngio.com/connected-cloud-computing-people-internet-business-connecting-people-connect-png-512_512.png" style={{"width" : "5%"}}/>
                  <div class="cardtitle">Avyzr is the Award-Winning Solution for <b>Advice Anytime, Anywhere</b></div>
                  <br/>
                  <i class="fa fa-desktop w3-margin-bottom w3-text-theme" style={{fontSize: 120}}></i>
                  <div class="benefit" className="benefit">In-browser, <b>no install</b> technology</div>
                  <div class="benefit" className="benefit">Pay only for the time <b>you want</b></div>
                  <div class="benefit" className="benefit"><b>Safe and reliable</b> for both parties</div>
                </div>
            </div>

            <div className="registerContainer" hidden>
                <p className="registerform-title" hidden>OK, let's get started. We'll have you set up in just a few minutes. Please fill your account, notification, and profile settings below.</p>
                <TestHome/>
                <p className="registerform-terms" hidden>By using Avyzr, you agree to the <u>Terms of Use</u>.</p>
                <p className="registerform-copyright">By using Avyzr, you agree to the <u>Terms of Use</u>.<br/>Copyright © 2021 Avyzr, Inc.<br/>All rights reserved.</p>
            </div>

            <div
                className="login uk-cover-container uk-background-secondary uk-flex uk-flex-center uk-flex-middle uk-overflow-hidden uk-light" hidden>
                <div className="uk-position-cover uk-overlay-primary"/>
                <div className="login-scrollable uk-flex uk-flex-center uk-flex-middle uk-position-z-index">

                    <div className="login-inner"
                         data-uk-scrollspy="cls: uk-animation-fade">

                        <div className="loginLogoContainer">
                            <img class="loginLogoImage" src="AVYZR-logo-horizontal-large.png"/>
                        </div>
            
                        <div className="loginContainer">

                            <form className="toggle-class" onSubmit={onLogin}>
                                <fieldset className="uk-fieldset">
                                    {loginInfo}
                                    <Input icon="user" placeholder="Email" type="email"
                                           onChange={e => setEmail(e.target.value)}/>
                                    <Input icon="lock" placeholder="Password" type="password"
                                           onChange={e => setPassword(e.target.value)}/>
                                    <div className="uk-margin-medium">
                                        <label><input className="uk-checkbox" type="checkbox"
                                                      onChange={e => setKeep(e.target.checked)} checked={keep} /> Keep me logged in</label>
                                    </div>
                                    <div className="uk-margin-bottom">
                                        <button type="submit"
                                                className="uk-button uk-button-primary uk-width-1-1 loginbutton">LOG IN
                                        </button>
                                    </div>
                                <div>
                                    <div className="uk-text-center">
                                        <a className="uk-link-reset uk-text-small toggle-class"
                                           data-uk-toggle="target: .toggle-class ;animation: uk-animation-fade" hidden>Need an account?
                                            Register now!</a>
                                    </div>
                                </div>                                    
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <p className="loginform-agree" hidden>By using Avyzr, you agree to the <u>Terms of Use and Privacy Policy</u>.</p>
            <p className="loginform-copyright" hidden>Copyright © 2021 Avyzr, Inc.  All rights reserved.</p>            
        </Div100vh>
    );
}


else
    return (
        <Div100vh>
            <ExpertIntroSlider />
            <div
                className="login uk-cover-container uk-background-secondary uk-flex uk-flex-center uk-flex-middle uk-overflow-hidden uk-light">
                <div className="uk-position-cover uk-overlay-primary"/>
                <div className="login-scrollable uk-flex uk-flex-center uk-flex-middle uk-position-z-index">

                    <Credits/>

                    <div className="login-inner"
                         data-uk-scrollspy="cls: uk-animation-fade">


                        <TopBar />        

                        <div className="elevatorpitch">
                        Earn money giving advice - anytime, anywhere</div>
                        <div className="elevatorpitch-sub">
                        People want your input and are willing to pay for your time.  Avyzr's award-winning no-install tech makes it easy to get started.</div>
                        <button class="startbutton toggle-credits" data-uk-toggle="target: .toggle-credits;animation: uk-animation-fade">Create Your Free Account</button>    

                        <div className="toggle-credits" hidden>

                            <form className="toggle-class" onSubmit={onLogin} hidden>
                                <fieldset className="uk-fieldset">
                                    {loginInfo}
                                    <Input icon="user" placeholder="Email" type="email"
                                           onChange={e => setEmail(e.target.value)}/>
                                    <Input icon="lock" placeholder="Password" type="password"
                                           onChange={e => setPassword(e.target.value)}/>
                                    <div className="uk-margin-small">
                                        <label><input className="uk-checkbox" type="checkbox"
                                                      onChange={e => setKeep(e.target.checked)} checked={keep} /> Keep me logged in</label>
                                    </div>
                                    <div className="uk-margin-bottom">
                                        <button type="submit"
                                                className="uk-button uk-button-primary uk-width-1-1 loginbutton">LOG IN
                                        </button>
                                    </div>
                                </fieldset>
                            </form>

                            <form className="toggle-class" onSubmit={onRegister}>
                                {registerInfo}
                                <Input icon="pencil" placeholder="First Name" type="text"
                                       onChange={e => setRegisterFirstName(e.target.value)}/>
                                <Input icon="pencil" placeholder="Last Name" type="text"
                                       onChange={e => setRegisterLastName(e.target.value)}/>

                                <Input icon="user" placeholder="Choose a Username" type="text"
                                       onChange={e => setRegisterUsername(e.target.value)}/>
                                <Input icon="mail" placeholder="Email" type="email"
                                       onChange={e => setRegisterEmail(e.target.value)}/>
                                <Input icon="lock" placeholder="Password" type="password"
                                       onChange={e => setRegisterPassword(e.target.value)}/>
                                <Input icon="lock" placeholder="Repeat Password" type="password" hidden
                                       onChange={e => setRegisterRepeatPassword(e.target.value)}/>
                                <div className="uk-margin-bottom">
                                    <button type="submit"
                                            className="uk-button uk-button-primary uk-border-pill uk-width-1-1 registerbutton">REGISTER
                                    </button>
                                </div>
                            </form>

                            <form className="toggle-password" hidden>
                                <Input icon="mail" placeholder="Email" type="email"/>
                                <div className="uk-margin-bottom">
                                    <button type="submit"
                                            className="uk-button uk-button-primary uk-border-pill uk-width-1-1">SEND CODE
                                    </button>
                                </div>
                            </form>

                            <div>
                                <div className="uk-text-center">
                                    <a className="uk-link-reset uk-text-small toggle-class"
                                       data-uk-toggle="target: .toggle-class ;animation: uk-animation-fade" hidden>Need an account?
                                        Register now!</a>
                                    <a className="uk-link-reset uk-text-small toggle-class"
                                       data-uk-toggle="target: .toggle-class ;animation: uk-animation-fade"><span
                                        data-uk-icon="arrow-left"/>Have an account?  Login now!</a>
                                </div>
                            </div>

                            {
                                Config.demo &&
                                <div className="disclaimer uk-margin-top uk-text-center toggle-class">
                                    THIS IS JUST A DEMO<br/>
                                    DATA WILL BE RESET PERIODICALLY
                                </div>
                            }

                        </div>

                        <form className="toggle-credits uk-text-center" hidden>
                        </form>

                        <div hidden>
                            <div className="uk-margin-top uk-text-center">
                                <a className="uk-link-reset uk-text-small toggle-credits"
                                   data-uk-toggle="target: .toggle-credits ;animation: uk-animation-fade" hidden><span
                                    data-uk-icon="arrow-left"/> Close Credits</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Div100vh>
    );
//  }
}

export default Login;
