import { setGlobal } from 'reactn';
import setAuthToken from "./actions/setAuthToken";
import initIO from "./actions/initIO";
import store from "./store";
import jwtDecode from 'jwt-decode';
import { setCookie, getCookie } from "./utils.js"

const init = () => {
    document.addEventListener('gesturestart', e => {
        e.preventDefault();
    });

    const token = getCookie('token');
    const userString = getCookie('user');
    const user = userString ? JSON.parse(userString) : null;
    
    console.log('init.js | user=',user);
    
    if (token) {
        setAuthToken(token);
        store.dispatch(initIO(token));
    }
    
    const state = {
        version: '2.0.0',
        entryPath: window.location.pathname,
        token,
        user: user || (token ? jwtDecode(token) : {}),
        rooms: [],
        searchResults: [],
        favorites: [],
        meetings: [],
        nav: 'profile',
        search: '',
        over: null,
        isPicker: false,
        messages: [],
        streams: [],
        inCall: false,
        video: true,
        audio: true,
        audioStream: null,
        videoStream: null,
        screenStream: null,
        callStatus: null,
        counterpart: null,
        callDirection: null,
        meeting: null,
        showPanel: true,
        panel: 'standard',
        newGroupUsers: [],
    };

    setGlobal(state).then(() => console.log('Global state init complete!', state));
}

export default init;
