import React from 'react';
import "./TopBar.sass";
import {FiMoreHorizontal, FiExternalLink, FiSettings, FiHome, FiPlusCircle, FiMenu, FiSmile, FiCpu} from 'react-icons/fi';
import Picture from "../../../components/Picture";
import {useGlobal} from "reactn";
import {useHistory} from "react-router-dom";
import { useToasts } from 'react-toast-notifications';
import {useSelector} from "react-redux";
import setAuthToken from "../../../actions/setAuthToken";

import { setCookie, getCookie } from "../../../utils.js"


const TopBar = () => {
    const onlineUsers = useSelector(state => state.io.onlineUsers);
    const [nav, setNav] = useGlobal('nav');
    const [token, setToken] = useGlobal('token');
    const setPanel = useGlobal('panel')[1];
    const setOver = useGlobal('over')[1];
    const [user, setUser] = useGlobal('user');
    const setAudio = useGlobal('audio')[1];
    const setVideo = useGlobal('video')[1];
    const setCallDirection = useGlobal('callDirection')[1];

    const aboutUs = () => window.open("https://www.avyzr.com/about", "_blank");

    const history = useHistory();
    const { addToast } = useToasts();

    const logout = async () => {
        const username = user.username;
        setCookie('token','',0);
        setCookie('pw','',0);
        setCookie('email','',0);
        setCookie('user','',0);        
        
        await setToken(null);
        await setAuthToken(null);
        await setUser({});
        addToast(`User ${username} logged out!`, {
            appearance: 'success',
            autoDismiss: true,
        })
        history.replace("/login");
    };

    const errorToast = content => {
        addToast(content, {
            appearance: 'error',
            autoDismiss: true,
        })
    };

/*
            <div className="link menuname" style={{ display: (token && user) ? "block" : "none"}}>{user.username}</div>
            <div className="divider" style={{ display: (token && user) ? "block" : "none"}}/>

*/

    return (
            <div className="top-bar uk-flex uk-flex-between uk-flex-middle">
                <div className="uk-flex uk-flex-middle">
                        <img className="topbarlogo" src="AVYZR-logo-horizontal-small.png" onClick={() => {
                                history.replace('/');
                           }}/>
                </div>
                <div className="nav">
                        <div className="logintopbutton toggle-buttons" data-uk-toggle="target: .introWrapper, .introBenefits, .login, .toggle-buttons, .loginform-agree, .loginform-copyright;animation: uk-animation-fade" type="button">
                            Log In  
                        </div>

                    <div className="uk-inline">
                        <div className="signupbutton toggle-buttons" data-uk-toggle="target: .introWrapper, .introBenefits, .registerContainer, .toggle-buttons;animation: uk-animation-fade" type="button">
                            <FiMenu hidden/>
                            Sign Up  
                        </div>
        
        
                        <div className="sidemenu" data-uk-dropdown="mode: click; offset: 5; boundary: .top-bar">
                            <div className="link" onClick={aboutUs}>About Avyzr <div className="icon"><FiExternalLink/></div></div>
                            {(user.level === 'root' || user.level === 'admin') && <div className="divider"/>}
                            {(user.level === 'root' || user.level === 'admin') && <div className="link" onClick={() => {
                                setOver(true);
                                history.replace('/admin');
                            }}>Admin Panel</div>}
                            <div className="link" onClick={logout}>Logout</div>
                        </div>
                    </div>
                </div>
            </div>
    );
}

export default TopBar;
