import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { CheckoutForm } from "./CheckoutForm";

const PUBLIC_KEY = "pk_test_51HeYSEIGCBBL6oWUgQYwJLTG6jhreSGntTTdC1C7bcpZcJsIH4ZRUKdW2uwxiN5tXkmj5Q5SXN4BUraVaZZkPDdv006EoCFnSV";

const stripeTestPromise = loadStripe(PUBLIC_KEY);

const Stripe = (props) => {

	  return (
	    <Elements stripe={stripeTestPromise}>
	      <CheckoutForm action={props.action} onSuccessHandler={props.onSuccessHandler}/>
	    </Elements>
	  );
};

export default Stripe;