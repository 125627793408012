import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import init from "./init";
import { Provider } from 'react-redux'
import store from './store'
import WebFont from 'webfontloader';

init();

// Loading UIkit Icons plugin.
UIkit.use(Icons);

WebFont.load({
  google: {
    families: ['Roboto', 'Open Sans', 'Alegreya', 'Nunito Sans', 'sans-serif']
  }
});

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
          <App />
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
