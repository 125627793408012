import React from 'react';

import { GoogleLogin } from 'react-google-login';
// refresh token
import { refreshTokenSetup } from './RefreshToken';

const clientId =
  '274994145103-i1duptrb3aq3o73feah95gfp2lcra11j.apps.googleusercontent.com';

const SocialLogin = (props) => {
  const onSuccess = async (res) => {
    console.log('Login Success: currentUser:', res.profileObj);
    console.log('Login Success: res:', res);


    refreshTokenSetup(res);

    props.onSuccess(res);

  };

  const onFailure = (res) => {
    console.log('Login failed: res:', res);
    alert(
      `Failed to login. 😢`
    );
  };

/*
        render={renderProps => (
          <button class="googlebutton" onClick={renderProps.onClick} disabled={renderProps.disabled}>
          Continue with Google
          </button>
          )}
*/

  return (
    <div>
      <GoogleLogin
        clientId={clientId}
        buttonText="Continue with Google"
        onSuccess={onSuccess}
        onFailure={onFailure}
        theme='light'
        cookiePolicy={'single_host_origin'}
        className='GoogleLoginButton'
        style={{ marginTop: '100px' }}
        isSignedIn={false}
      />
    </div>
  );
}

export default SocialLogin;