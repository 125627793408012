import React, {useEffect, useState, useRef} from 'react';
import {useGlobal} from "reactn";
import "./Panel.sass";
import TopBar from "./components/TopBar";
import SearchBar from "./components/SearchBar";
import NavBar from "./components/NavBar";
import MeetingBar from "./components/MeetingBar";
import Room from "./components/Room";
import Meeting from "./components/Meeting";
import User from "./components/User";
import getRooms from "../../actions/getRooms";
import search from "../../actions/search";
import getFavorites from "../../actions/getFavorites";
import getMeetings from "../../actions/getMeetings";
import {useDispatch, useSelector} from "react-redux";
import Actions from "../../constants/Actions";
import Settings from "./components/Settings";
import {useHistory, useLocation} from "react-router-dom";

import Picture from "../../components/Picture";
import Config from "../../config";
import axios from "axios";
import * as moment from "moment";

import Swiper from 'react-id-swiper';

import { setCookie, getCookie } from "../../utils.js"


const CubeEffect = () => {
    const params = {
      effect: 'cube',
      grabCursor: true,
      cubeEffect: {
        shadow: true,
        slideShadows: true,
        shadowOffset: 20,
        shadowScale: 0.94,
      },
      pagination: {
        el: '.swiper-pagination',
      }
    }
    return (
      <Swiper {...params}>
        <div style={{ backgroundImage:'url(/api/images/bviz4jqyh4mc9ok7hj711gkq2kf3r7xa3zya17tsna8ns0vmw3u71lk3f9y9x9piz3a1pni6nsv9exmjgq5opjqmc4dmnxfwhky7s3e09izhmc1fjmlf15k65f7f66f9e62ceb6c2bbfb9f5/256)' }} />
        <div style={{ backgroundImage:'url(/api/images/bviz4jqyh4mc9ok7hj711gkq2kf3r7xa3zya17tsna8ns0vmw3u71lk3f9y9x9piz3a1pni6nsv9exmjgq5opjqmc4dmnxfwhky7s3e09izhmc1fjmlf15k65f7f66f9e62ceb6c2bbfb9f5/256)' }} />
        <div style={{ backgroundImage:'url(http://www.conservativebusiness.biz/wp-content/uploads/2017/07/tumblr_n6rzsunanL1st5lhmo1_1280-600x6002-1.png)' }} />
        <div style={{ backgroundImage:'url(http://www.conservativebusiness.biz/wp-content/uploads/2017/07/tumblr_n6rzsunanL1st5lhmo1_1280-600x6002-1.png)' }} />
        <div style={{ backgroundImage:'url(http://www.conservativebusiness.biz/wp-content/uploads/2017/07/tumblr_n6rzsunanL1st5lhmo1_1280-600x6002-1.png)' }} />
      </Swiper>
    )
  };

  const FadeEffect = () => {
    const params = {
      spaceBetween: 30,
      effect: 'fade',
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    }
    return (
      <Swiper {...params}>
        <div>Slide #1</div>
        <div>Slide #2</div>
        <div>Slide #3</div>
        <div>Slide #4</div>
        <div>Slide #5</div>
      </Swiper>
    )
  };

const stripeSetup = async user => {

if(!user.stripeAccountID)

  try {
          alert("To receive payments, you must first set up your free Stripe account.  You will be directed to Stripe to complete setup.");

        const response = await axios.post(
          (Config.url || '') + "/api/stripe/charge",
          {
            amount: -2,
            email: user.email,
            userid: user.username,
          }
        );

        console.log("Stripe 35 | data", response.data);
        if (response.data.success) {
          console.log("Stripe Account Creation  |  successful!");
          window.location.replace(response.data.stripeAccountLinkURL);
        }
    } catch (error) {
    console.log("Stripe Account Creation | ", error);

    }
  else    
  

  try {
    const response = await axios.post(
      (Config.url || '') + "/api/stripe/charge",
      {
        amount: -3,
        email: user.email,
        userid: user.username,
      }
    );

    console.log("Stripe 35 | data", response.data);
    if (response.data.success) {
      console.log("Stripe Account Retrieve  |  successful!");
    }
  } catch (error) {
    console.log("Stripe Account Retrieve | ", error);

  }

}

const EarningsScreen = ({meetingList}) => {
    const user = useGlobal('user')[0];
    const [over, setOver] = useGlobal('over');
    const back = () => setOver(false);    

    console.log('EarningsScreen | meetingList:',meetingList);

    var totalEarnings = 0;
    var totalCalls = 0;
    var totalSeconds = 0;
    var totalMinutes = 0;
    var totalBalance = 0;

    meetingList.forEach(parseMeeting);
    console.log('totalSeconds =',totalSeconds);
    totalMinutes = Math.floor(totalSeconds / 60);
    totalBalance = totalEarnings - (user.totalCashOut ? user.totalCashOut : 0);
    totalEarnings = totalEarnings.toFixed(2);
    totalBalance = totalBalance.toFixed(2);

    function parseMeeting(item) {
      if(item.totalCharged)
        totalEarnings += Math.floor(item.totalCharged * 0.8) / 100;

      if(item.lastLeave) {
        var ms = moment(item.lastLeave,"YYYY-MM-DDTHH:mm:ss").diff(moment(item.lastEnter,"YYYY-MM-DDTHH:mm:ss"));        
        totalSeconds += moment.duration(ms).seconds();
      }


      totalCalls++;
    }


    return (
        <div class="earnings-topsection">
            <div class="earnings-topsection-header">My Earnings Dashboard</div>                         
            <p class="earnings-title">TOTAL EARNINGS</p>
            <p class="earnings-number">${totalEarnings}</p>
            <button class="uk-button uk-button-primary uk-button-large cashoutbutton" onClick={() => {
                if(totalEarnings > 0) alert('Please e-mail support@avyzr.com to cash out your balance.');
                else alert('You have not had any calls yet.  Share your link to let people know you are here and to earn money helping them.  Once you have had at least one call you can cash out your earnings at any time using this button.');
    }}>CASH OUT ${totalBalance}</button>
            <div class="earnings-statscontainer uk-flex uk-flex-middle uk-flex-center"> 
                    <div class="earnings-callstitle">CALLS: <br/><b>{totalCalls}</b></div>
                    <div class="earnings-balance">BALANCE: <br/><b>${totalBalance}</b></div>
            </div>
            <div class="earnings-transactionlistheader">Transaction History</div>                 
        </div>
    );                

}

const HomeTop = ({meetingList}) => {
    const user = useGlobal('user')[0];
    const [over, setOver] = useGlobal('over');
    const back = () => setOver(false);    

    console.log('HomeTop | meetingList:',meetingList);

    var totalEarnings = 0;
    var totalCalls = 0;
    var totalSeconds = 0;
    var totalMinutes = 0;
    var totalBalance = 0;

/*
    meetingList.forEach(parseMeeting);
    console.log('totalSeconds =',totalSeconds);
    totalMinutes = Math.floor(totalSeconds / 60);
    totalBalance = totalEarnings - (user.totalCashOut ? user.totalCashOut : 0);
    totalEarnings = totalEarnings.toFixed(2);
    totalBalance = totalBalance.toFixed(2);

    function parseMeeting(item) {
      if(item.totalCharged)
        totalEarnings += Math.floor(item.totalCharged * 0.8) / 100;

      if(item.lastLeave) {
        var ms = moment(item.lastLeave,"YYYY-MM-DDTHH:mm:ss").diff(moment(item.lastEnter,"YYYY-MM-DDTHH:mm:ss"));        
        totalSeconds += moment.duration(ms).seconds();
      }

      totalCalls++;
    }
*/

//    <div class="welcome-topsection-header">My Earnings</div>                         
//    <p class="welcome-topsection-number">${totalEarnings}</p>

    
    return (
        <div class="welcome-topsection">
            <div class="welcome-topsection-header">Getting Started</div>                         
        </div>
    );                

}



const WelcomeScreen = ({meetingList}) => {
    const user = useGlobal('user')[0];

    const [over, setOver] = useGlobal('over');

    const back = () => setOver(false);

    const textAreaRef = useRef(null);    

    const [showCopied, setShowCopied] = React.useState(false);

    const shareMyLink = (e) => {
                
        if(navigator.share) {
            navigator
            .share({
              title: "Questions? You have to see this",
              text: "I'm now available on Avyzr. Ask me anything - check out my link!",
              url: uniqueLink(user),
            })
            .then(() => {
              console.log('Successfully shared');
            })
            .catch(error => {
              console.error('Something went wrong sharing', error);
            });
        }    
        
        else { copyToClipboard(e) }
    }
        
    const copyToClipboard = (e) => {
        
        var textField = document.createElement('textarea');
        
        textField.innerText = uniqueLink(user);
        document.body.appendChild(textField);
        textField.setSelectionRange(0, 99999);
        textField.select();
        document.execCommand('copy');
        textField.remove();

/*        
        textAreaRef.current.select();
        document.execCommand('copy');
        e.target.focus();
*/
        setShowCopied(true);

    };

    function uniqueLink(user) {
      return 'http://avyzr.com/u/'+user.username;
    }    
    
    var profilename='';
    var profiledesc='';
    var profileFirstInit='';
    var profileLastInit='';
    var profilePicShieldedID='';
    var profileTagline = '';
    var profileUsername = '';
    var profilePrice = '';
    var profileMinutes = '';
 
    if(user.tagLine)
        profileTagline = user.tagLine;
    if(user.firstName && user.lastName)
        profilename=user.firstName + ' ' + user.lastName;
    if(user.firstName)
        profileFirstInit = user.firstName.substr(0,1);
    if(user.lastName)
        profileLastInit = user.lastName.substr(0,1);
    if(user.picture)
        profilePicShieldedID = user.picture.shieldedID; 
    if(user.username)
        profileUsername=user.username;   
    if(user.price1)
        profilePrice = user.price1;
    if(user.minutes1)
        profileMinutes = user.minutes1;


    const Picture = () => {
                if (profilePicShieldedID != '')
                    return <img class="profilepic" src={`${Config.url || ''}/api/images/${profilePicShieldedID}/256`} alt="Picture" className="picture profilepic"/>;
                else
                    return <div className="img">N/A</div>;
            };
/*    
    useEffect(() => {
        console.log('loading Panel WelcomeScreen... | useEffect');
    });
*/
    console.log('loading Panel welcomescreen...');

//    stripeSetup(user);

    const setToken = useGlobal('token')[1];
    const setUser = useGlobal('user')[1];
    const [firstEntryPath, setFirstEntryPath] = useGlobal('firstEntryPath');    
    const history = useHistory();

    
if(!user.price1) {
    const username = user.username;
    localStorage.removeItem('token');
    setCookie('token','',0);
    setCookie('pw','',0);
    setCookie('email','',0);
    setCookie('user','',0);
    setToken(null);
    setUser({});
/*        addToast(`User ${username} logged out!`, {
        appearance: 'success',
        autoDismiss: true,
    })
*/
    history.replace((firstEntryPath && firstEntryPath.startsWith("/u/")) ? firstEntryPath : "/");   
            window.location = "https://avyzr.com" + firstEntryPath;
    
    return (
        <div>Thank you for visiting Avyzr.  Going back... if you are not redirected momentarily, please refresh.</div>
           );
}
        
else if (1)
    return (
    <div><HomeTop meetingList={meetingList} />
    <div className="home-howitworks">
        <img className="home-howitworks-heroimage" src="https://steveadegunloye.com/wp-content/uploads/2020/11/social-media-and-teen.jpg" hidden />    
        <div className="home-howitworks-step1title">
            Share your unique link
            <form style={{ "width" : "100%"}}><textarea readonly disabled class="home-howitworks-linktext" ref={textAreaRef} value={'https://avyzr.com/u/'+profileUsername} style={{ background:'transparent',borderWidth:0, fontWeight:600, "width" : "100%", textAlign: "center",
    flex: 1 }}></textarea></form>
            <button class="uk-button uk-button-primary uk-button-large uk-align-center sharebutton" onClick={shareMyLink}>Share My Link</button>
            <button class="uk-button uk-button-primary uk-button-large uk-align-center copybutton" onClick={copyToClipboard}>Copy to Clipboard</button>
            <div class="home-howitworks-copied" style={{ display: (showCopied ? 'block' : 'none') }}>
            Copied! See below for some examples of where to paste it.
            </div>
        </div>
        <hr className="home-howitworks-hr" style={{ display:'none'}}/>
        <div className="home-howitworks-step2title">
            Let the world know you're available to help!  Share your link with everyone through:
            <div className="home-howitworks-step2text">
                <ul className="home-howitworks-ul">
                <li className="home-howitworks-example">YouTube</li>                    
                <li className="home-howitworks-example">Text Messages</li>
                <li className="home-howitworks-example">E-Mail Blasts</li>
                <li className="home-howitworks-example">Facebook</li>
                <li className="home-howitworks-example">Instagram</li>
                <li className="home-howitworks-example">Twitter</li>
                <li className="home-howitworks-example">LinkedIn</li>
                <li className="home-howitworks-example">Web Sites</li>
                <li className="home-howitworks-example">Yelp or Profile Pages</li>
                <li className="home-howitworks-example">Blog Posts / Articles</li>
                <li className="home-howitworks-example">Forums</li>
                </ul>
            </div>
        </div>
        <hr className="home-howitworks-hr"/>
        <div className="home-example-title">
        Text Message Example
        </div>
        <div className="home-example-image-wrapper">
        <img className="home-example-image" src="example1a.png"/>
        <button class="uk-button uk-button-primary uk-button-large uk-align-center copybutton2" onClick={copyToClipboard}>Copy Text Message</button>
            <div class="home-howitworks-copied" style={{ display: (showCopied ? 'block' : 'none') }}>
            Copied!
            </div>                        
        </div>
        <hr className="home-howitworks-hr"/>
        <div className="home-example-title">
        Facebook Post Example
        </div>
        <div className="home-example-image-wrapper">
        <img className="home-example-image" src="example2a.jpg"/>
        <button class="uk-button uk-button-primary uk-button-large uk-align-center copybutton2" onClick={copyToClipboard}>Copy Facebook Post Text</button>
            <div class="home-howitworks-copied" style={{ display: (showCopied ? 'block' : 'none') }}>
            Copied!
            </div>                        
        </div>
        <hr className="home-howitworks-hr"/>
        <div className="home-example-title">
        Web/Blog Post Example
        </div>
        <div className="home-example-image-wrapper">
        <img className="home-example-image" src="example3a.png"/>
        <button class="uk-button uk-button-primary uk-button-large uk-align-center copybutton2" onClick={copyToClipboard}>Copy Web/Blog Post Text</button>
            <div class="home-howitworks-copied" style={{ display: (showCopied ? 'block' : 'none') }}>
            Copied!
            </div>                        
        </div>
        <hr className="home-howitworks-hr" style={{ display: 'none'}}/>
        <div class="home-howitworks-header">How It Works</div>                         
        <div className="home-howitworks-step3title">
        What happens when someone clicks my link?
        </div>
        <div className="home-howitworks-step3text">
        <ul className="home-howitworks-ul">
        <li className="home-howitworks-3li">They go to your profile, see your pricing and enter their credit card info to continue</li>
        <li className="home-howitworks-3li">We'll text you a link to a two-way chat screen to arrange a call together</li>
        <li className="home-howitworks-3li">When you're ready, they start the call</li>
        <li className="home-howitworks-3li">You receive 80% of the total call value (we only get 20% commission on the actual money you earn - no fees)</li>        
        </ul>
        </div>


    </div>
    </div>
  );

else if(0)
    return (
    <div className="home-howitworks">
        <div className="home-howitworks-maintitle">
        How It Works</div>
        <div className="home-howitworks-step1title">
            1- Share your unique link
            <form style={{ "width" : "100%"}}><textarea readonly class="home-howitworks-linktext" ref={textAreaRef} value={'https://avyzr.com/u/'+profileUsername} style={{ background:'transparent',borderWidth:0, fontWeight:600, "width" : "100%", textAlign: "center",
    flex: 1 }}></textarea></form>
            <button class="uk-button uk-button-primary uk-button-large uk-align-center copybutton" onClick={copyToClipboard}>Copy My Link</button>
        </div>
        <div className="home-howitworks-step2title">
        2- Receive call requests
            <div className="home-howitworks-step2text">
            We'll text you when someone wants to do a call with you, with a link to the chat / call screen.
            </div>
        </div>
        <div className="home-howitworks-step3title">
        3- Get paid to help people
            <div className="home-howitworks-step3text">
            Earn money by answering questions and giving advice
            </div>
        </div>
    </div>
  );

else    
    return (
                    <div class="profileview">
                        <div class="profilecard" >
                          <Picture/>

                          <h2 class="settings-profiletitle">{profilename}</h2>
                          <p class="settings-profilesubtitle">{profileTagline}</p>
                          <p class="settings-profileprice">${profilePrice/100} per minute</p>
                          <p class="settings-profileminutes">(minimum {profileMinutes} minutes)</p>
                            <div class="profilelink">
        
                            Your unique link is <form style={{ "width" : "95%"}}><textarea readonly class="profilelinktextarea" ref={textAreaRef} value={'https://avyzr.com/u/'+profileUsername} style={{ background:'transparent',borderWidth:0, fontWeight:600, "width" : "100%", textAlign: "center",
flex: 1 }}></textarea></form>
                            <button class="uk-button uk-button-primary uk-button-large copybutton" onClick={copyToClipboard}>Copy Link</button>
                            </div>
                        </div>        
                    </div>
                 
    );            
}
    
const Panel = () => {
    const nav = useGlobal('nav')[0];
    const token = useGlobal('token')[0];
    const searchText = useGlobal('search')[0];
    const rooms = useSelector(state => state.io.rooms);
    const [searchResults, setSearchResults] = useGlobal('searchResults');
    const [favorites, setFavorites] = useGlobal('favorites');
    const [meetings, setMeetings] = useGlobal('meetings');
    const [callStatus, setCallStatus] = useGlobal('callStatus');
    const [over, setOver] = useGlobal('over');
    const refreshMeetings = useSelector(state => state.io.refreshMeetings);

    const [firstEntryPath, setFirstEntryPath] = useGlobal('firstEntryPath');
    
    const dispatch = useDispatch();
    const location = useLocation();

    const currentUser = useGlobal('user')[0];

    
    useEffect(() => {
        console.log('loading Panel... | useEffect');

        window.preChatPage = window.location.pathname;
        window.preChatOver = over;
    
        getRooms().then(res => dispatch({type: Actions.SET_ROOMS, rooms: res.data.rooms})).catch(err => console.log(err));
        search().then(res => setSearchResults(res.data.users)).catch(err => console.log(err));
        getFavorites().then(res => setFavorites(res.data.favorites)).catch(err => console.log(err));
        getMeetings().then(res => setMeetings(res.data.meetings)).catch(err => console.log(err));

        console.log('Panel index.js | searchResults = ');
        console.log(searchResults);
        console.log('-- searchResultsList.entries = ');
        for (let [key, value] of searchResultsList.entries()) {
         console.log(value);
        }
    
    }, [setSearchResults, setFavorites]);

    useEffect(() => {
        getMeetings().then(res => setMeetings(res.data.meetings)).catch(err => console.log(err));
    }, [refreshMeetings]);

    const roomsList = rooms.map(room => <Room key={room._id} room={room} />);
    const searchResultsList = searchResults.map(user => <User key={user._id} user={user} />);
    const favoritesList = favorites.map(room => <Room key={room._id} room={room} />);
    const meetingsList = meetings.map(meeting => <Meeting key={meeting._id} meeting={meeting} />);

    const Notice = ({text}) => <div className="notice">{text}</div>

    return (
        <div className="panel">
            <TopBar/>
            {callStatus === 'in-call' && (!location.pathname.startsWith('/meeting') || over === false) && <MeetingBar/>}
            <div className="rooms">
                {nav === 'profile' && <WelcomeScreen meetingList={meetings}/>}                
                {nav === 'rooms' && <div class="roomslist-header">My Message Room History</div>                         }
                {nav === 'rooms' && roomsList}
                {nav === 'rooms' && rooms.length === 0 && <Notice text="You have no incoming messages yet.  Share your link to let people know you are here and to earn money helping them." />}
                {nav === 'search' && searchResultsList}
                {nav === 'search' && searchResults.length === 0 && <Notice text={`There are no search results for "${searchText}"`} />}
                {nav === 'meetings' && <EarningsScreen meetingList={meetings}/>}
                {nav === 'meetings' && meetingsList}
                {nav === 'meetings' && meetings.length === 0 && <Notice text="You have not had any calls yet.  Share your link to let people know you are here and to earn money helping them." />}                                 
                {nav === 'settings' && <Settings/>}
            </div>
                 {currentUser.price1 && <NavBar/>}
        </div>
    );
}

export default Panel;
