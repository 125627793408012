import React, {useRef, useState} from 'react';
import {useGlobal} from "reactn";
import "./Settings.sass";
import {FiCalendar, FiMessageCircle, FiStar, FiUsers, FiSearch} from 'react-icons/fi';
import {useToasts} from "react-toast-notifications";
import {useDispatch, useSelector} from "react-redux";
import upload from "../../../actions/uploadImage";
import Config from "../../../config";
import {FiEdit2} from "react-icons/fi";
import changePicture from "../../../actions/changePicture";
import {useHistory} from "react-router-dom";

import { setCookie, getCookie } from "../../../utils.js"

import Popup from "./Popup";

import Switch from 'react-switch';
import { Component } from "react";

import Avatar from 'react-avatar';

class BasicExample extends Component {
  constructor() {
    super();
    this.state = { checked: false };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(checked) {
    this.setState({ checked });
  }

  render() {
    return (
            <label>
            <div className="uk-grid">
              <div className="uk-width-expand">
              <span>Text Notifications</span></div>
              <div className="uk-width-1-3">
                  <Switch
                    checked={this.state.checked}
                    onChange={this.handleChange}
                    onColor="#86d3ff"
                    onHandleColor="#2693e6"
                    handleDiameter={30}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={20}
                    width={48}
                    className="react-switch"
                    id="material-switch"
                  />
                </div>
            </div>
            </label>
    );
  }
}

const ProfileScreen = () => {
    const [user, setUser] = useGlobal('user');

    const [over, setOver] = useGlobal('over');

    const [keep, setKeep] = useState(true);   

    const back = () => setOver(false);

    const textAreaRef = useRef(null);    

    const copyToClipboard = (e) => {
        textAreaRef.current.select();
        document.execCommand('copy');
        e.target.focus();
    };

    function uniqueLink(user) {
      return 'http://avyzr.com/u/'+user.username;
    }    
    
    var profilename='';
    var profiledesc='';
    var profileFirstInit='';
    var profileLastInit='';
    var profilePicShieldedID='';
    var profileTagline = '';
    var profileUsername = '';
    var profilePrice = '';
    var profileMinutes = '';
    var profileTextMessage = false;
    var profileCellPhone = '';

    if(user.tagLine)
        profileTagline = user.tagLine;
    if(user.firstName && user.lastName)
        profilename=user.firstName + ' ' + user.lastName;
    if(user.firstName)
        profileFirstInit = user.firstName.substr(0,1);
    if(user.lastName)
        profileLastInit = user.lastName.substr(0,1);
    if(user.picture)
        profilePicShieldedID = user.picture.shieldedID; 
    if(user.username)
        profileUsername=user.username;   
    if(user.price1)
        profilePrice = user.price1;
    if(user.minutes1)
        profileMinutes = user.minutes1;
    if(user.textMessage)
        profileTextMessage = user.textMessage;
    if(user.phoneNumber)
        profileCellPhone = user.phoneNumber;


    const Picture = () => {
                if (profilePicShieldedID != '')
                    return <img class="profilepic" src={`${Config.url || ''}/api/images/${profilePicShieldedID}/256`} alt="Picture" className="picture profilepic"/>;
                else
                    return <div className="profilepage-defaultpic"><Avatar name={profilename} round={true} color="#5500FF" size="150" /></div>;
            };
    

    const [popup, setPopup] = useState(null);    
    
    const fileInput = useRef(null);

    const change = async image => {
        const picture = await upload(image, null, () => {}, 'square');
        await changePicture(picture.data.image._id);
        const newUser = {...user, picture: picture.data.image};
        localStorage.setItem('user', JSON.stringify(newUser));
        await setUser(newUser);
    };

    const remove = async () => {
        await changePicture();
        const newUser = {...user, picture: undefined};
        localStorage.setItem('user', JSON.stringify(newUser));
        await setUser(newUser);
    };


    return (
      <div class="settings-wrapper">
        <div class="settings-profileviewheader">My Profile</div>
        <div class="settings-profileview">
            <div class="settings-profilecard" >
                <input
                    className="file-input"
                    type="file"
                    ref={fileInput}
                    accept="image/*"
                    onChange={e => change(e.target.files[0])}
                />
                <div className="profilepic-settings-a" onClick={() => fileInput && fileInput.current && fileInput.current.click()}>
                    <Picture/>
                </div>


              <h2 class="settings-profiletitle">{profilename}</h2>
              <p class="settings-profilesubtitle">{profileTagline}</p>
              <p class="settings-profileprice">${profilePrice/100} per minute</p>
              <p class="settings-profileminutes">(minimum {profileMinutes} minutes)</p>

            <button className="uk-margin-small-top uk-button uk-button-secondary settings-editprofilebutton" onClick={() => { setPopup('edit');}}>Edit Profile</button>

            {popup && <Popup onClose={shouldUpdate => {
                console.log('Popup onClose()'+shouldUpdate);
                setPopup(null);
            }} user={user} type={popup} />}

            </div>        
        </div>
        <div class="settings-generalheader">General Settings</div>
        <div class="settings-generalcard">

            <form class="uk-form-horizontal uk-margin-large">

                <div class="uk-margin">
                    <label class="uk-form-label" for="form-horizontal-select">Text Notifications</label>
                    <div class="uk-form-controls">
                        <select class="uk-select" id="form-horizontal-select" disabled>
                            <option selected>Yes (strongly recommended)</option>
                            <option>No (not recommended)</option>
                        </select>
                    </div>
                </div>

                <div class="uk-margin">
                    <label class="uk-form-label" for="form-horizontal-text">Cell Phone Number</label>
                      <p class="settings-profilesubtitle">{profileCellPhone}</p>

                    <div class="uk-form-controls" hidden>
                        <input class="uk-input" id="form-horizontal-text" type="text" placeholder="Some text..."/>
                    </div>
                </div>

            </form>
        </div>


      </div>
    );            
}


const Settings = () => {
    const { addToast } = useToasts();
    const history = useHistory();

    const [user, setUser] = useGlobal('user');
    const setToken = useGlobal('token')[1];
    const setPanel = useGlobal('panel')[1];

    const fileInput = useRef(null);

    const [popup, setPopup] = useState(null);    
    
    const change = async image => {
        const picture = await upload(image, null, () => {}, 'square');
        await changePicture(picture.data.image._id);
        const newUser = {...user, picture: picture.data.image};
        localStorage.setItem('user', JSON.stringify(newUser));
        await setUser(newUser);
    };

    const remove = async () => {
        await changePicture();
        const newUser = {...user, picture: undefined};
        localStorage.setItem('user', JSON.stringify(newUser));
        await setUser(newUser);
    };

    const logout = async () => {
        const username = user.username;
        localStorage.removeItem('token');
        setCookie('token','',0);
        setCookie('pw','',0);
        setCookie('email','',0);
        setCookie('user','',0);

        await setToken(null);
        await setUser({});
        
        
        addToast(`User ${username} logged out!`, {
            appearance: 'success',
            autoDismiss: true,
        })
        history.replace("/login");
    };

    const notAvailable = name => {
        addToast(`The ${name} feature is not available yet.`, {
            appearance: 'warning',
            autoDismiss: true,
        })
    };

    const Picture = () => {
        if (user.picture)
            return <img src={`${Config.url || ''}/api/images/${user.picture.shieldedID}/256`} alt="Picture" />;
        else
            return <div className="img">{user.firstName.substr(0,1)}{user.lastName.substr(0,1)}</div>;
    };

    if (1)
        return (
            <ProfileScreen /> );
    else
        return (
        <div className="settings uk-flex uk-flex-column uk-padding-small">

            <ProfileScreen />

            <input
                className="file-input"
                type="file"
                ref={fileInput}
                accept="image/*"
                onChange={e => change(e.target.files[0])}
            />
            <div className="profilepic-settings uk-margin-small" onClick={() => fileInput && fileInput.current && fileInput.current.click()}>
                <Picture/>
                <div className="overlay">
                    <div className="text"><FiEdit2/></div>
                </div>
            </div>
            <button className="uk-margin-small-top uk-button uk-button-secondary" onClick={() => { setPopup('edit');}}>Edit Profile</button>
            <button className="uk-margin-small-top uk-button uk-button-secondary" onClick={logout}>Logout</button>

            {popup && <Popup onClose={shouldUpdate => {
                console.log('Popup onClose()'+shouldUpdate);
                setPopup(null);
            }} user={user} type={popup} />}
        </div>
    );
}

export default Settings;
